import React, {
	useContext,
	useCallback,
} from "react";
import {
	Box,
	FormControl,
	FormControlLabel,
	Paper,
	Radio,
	RadioGroup,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import { LoadingStatusTable} from "../Shared/LoadingStatus/LoadingStatus";
import useFetchData from "../Shared/FetchData/FetchData";
import { DataContext } from "../../App";
import { getFormattedDateTime } from "../../utils/time";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function ErrorList() {

	const context = useContext(DataContext);
	const [errorType, setErrorType] = React.useState("errors");
	const handleRadioButtonChange = (e) => {
		setErrorType(e.target.value);
	};

	const errorList = useFetchData(useCallback(() =>
		errorType == "errors" ? context.dataProvider.getPlatformErrors() : context.dataProvider.getPlatformWarnings(),
	[context.dataProvider, errorType]));

	const TableData = () => {
		if (errorList.results.length === 0) {
			return (
				<TableRow>
					<TableCell colSpan="9" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}

		return (
			errorList.results.map((item, i) =>
				<TableRow key={i}>
					<TableCell>{item.level}</TableCell>
					<TableCell>{item.log}</TableCell>
					<TableCell>{getFormattedDateTime(new Date(item.timestamp))}</TableCell>
					<TableCell>{item.request_id}</TableCell>
					<TableCell>{item.message}</TableCell>
					<TableCell>{item.session_id}</TableCell>
				</TableRow>
			)
		);
	};

	return (
		<Box id="queue-latency">
			<PageHeading pageTitle={"Errors/Warnings"} sx={{ mb: 1 }}/>
			<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
				<FormControl>
					<RadioGroup
						row
						name="error-type-radio-group"
						value={errorType}
						onChange={handleRadioButtonChange}
					>
						<FormControlLabel value="errors" control={<Radio />} label="Errors" />
						<FormControlLabel value="warnings" control={<Radio />} label="Warnings" />
					</RadioGroup>
				</FormControl>
				<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell className="table-header">Type</TableCell>
								<TableCell className="table-header">Log Group</TableCell>
								<TableCell className="table-header">Time</TableCell>
								<TableCell className="table-header">Request ID</TableCell>
								<TableCell className="table-header">Message</TableCell>
								<TableCell className="table-header">Session ID</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{errorList.isLoading ?
								<LoadingStatusTable message={"Loading..."} colSpan={9} />
								:
								<TableData />
							}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	);
}