import React, {
	useCallback,
	useEffect,
	useState
} from "react";
import { DataContext } from "../../../../App";
import useFetchData from "../../../Shared/FetchData";
import { TextfieldBasic as TextField } from "../../../Shared/TextField/TextField";
import {
	Box,
	Button,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow
} from "@mui/material";
import ModalFull from "../../../Shared/Modal/Modal";


export default function WarehouseMappings({
	existingSeller,
	onChangeWarehouseMappings,
	onPanelsVisibleChange,
}) {
	const context = React.useContext(DataContext);
	const warehouses = useFetchData(useCallback(() => context.dataProvider.getWarehouseResponse(), [context.dataProvider]));

	const [page, setPage] = useState(0);
	const [rowsPerPage,] = useState(10);

	const [warehouseMappings, setWarehouseMappings] = useState(new Map());

	const page_data = {
		warehouses: {
			resultsData: warehouses.results,
			status: warehouses.status,
			isLoading: warehouses.isLoading,
		},
	};

	const [state, setState] = useState({
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	const submitMappingsAndClosePane = (mappings) => {
		onChangeWarehouseMappings(mappings);
		onPanelsVisibleChange({
			isSellerDetailsPanelVisible: false,
			isFinanceDetailsPanelVisible: true,
			isWarehouseMappingsPanelVisible: false,
		});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
			};
		});
	};

	const showZeroMappingsConfirmationModel = (mappings) => {
		initialModalState();

		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Seller missing warehouse mappings",
				modalBodyMessage1: "Please confirm you want to proceed without any warehouse mappings?",
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes",
				modalButtonActionType2: "function",
				modalButtonAction2: () => {
					submitMappingsAndClosePane(mappings);
				},
				modalButtonColor2: "primary",
				isModalOpen: true,
			};
		});

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});
	};

	const onSubmitChanges = () => {
		const mappings = Array.from(warehouseMappings.values());

		if (mappings.length === 0) {
			showZeroMappingsConfirmationModel(mappings);
			return;
		}

		submitMappingsAndClosePane(mappings);
	};

	const initialModalState = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "",
				modalBodyMessage1: "",
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "Close",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "",
				modalButtonText2: "",
				modalButtonActionType2: "",
				modalButtonAction2: "",
				modalButtonColor2: state.modalButtonColor2,
			};
		});
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	// If a seller object is passed in with non-empty mappings, then we should populate the state
	useEffect(() => {
		const warehouseMappingsMap = new Map(warehouseMappings);

		if (existingSeller && existingSeller.seller_warehouse_mappings) {
			existingSeller.seller_warehouse_mappings.forEach((mapping) => {
				warehouseMappingsMap.set(mapping.warehouse_id, mapping);
			});
		}

		setWarehouseMappings(warehouseMappingsMap);
	}, [existingSeller]);

	const rows = () => {
		if (!page_data.warehouses.resultsData) {
			return [];
		}

		return page_data.warehouses.resultsData.sort((a, b) => a.name > b.name ? 1 : -1);
	};

	const toggleWarehouseMapping = (warehouseID) => {
		let warehouseMappingsMap = new Map(warehouseMappings);

		if (warehouseMappingsMap.has(warehouseID)) {
			warehouseMappingsMap.delete(warehouseID);
		} else {
			warehouseMappingsMap.set(warehouseID, {
				warehouse_id: warehouseID,
				warehouse_client_id: 0,
				sub_warehouse_id: 0,
			});
		}

		setWarehouseMappings(warehouseMappingsMap);
	};

	const setWarehouseClientID = (warehouseID, warehouseClientID) => {
		const warehouseMappingsMap = new Map(warehouseMappings);
		const warehouseMapping = warehouseMappingsMap.get(warehouseID);

		if (warehouseMapping) {
			warehouseMapping.warehouse_client_id = Number(warehouseClientID);
			warehouseMappingsMap.set(warehouseID, warehouseMapping);
		}

		setWarehouseMappings(warehouseMappingsMap);
	};

	const setSubWarehouseID = (warehouseID, subWarehouseID) => {
		const warehouseMappingsMap = new Map(warehouseMappings);
		const warehouseMapping = warehouseMappingsMap.get(warehouseID);

		if (warehouseMapping) {
			warehouseMapping.sub_warehouse_id = Number(subWarehouseID);
			warehouseMappingsMap.set(warehouseID, warehouseMapping);
		}

		setWarehouseMappings(warehouseMappingsMap);
	};


	const handleBackButton = () => {
		onPanelsVisibleChange({
			isSellerDetailsPanelVisible: true,
			isWarehouseMappingsPanelVisible: false,
			isFinanceDetailsPanelVisible: false,
		});
	};

	const WarehouseMappingTableRow = ({
		warehouse
	}) => {
		return (
			<TableRow>
				<TableCell>
					{warehouse.name}
				</TableCell>
				<TableCell>
					<Switch
						checked={warehouseMappings.has(warehouse.id)}
						onChange={() => toggleWarehouseMapping(warehouse.id)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						type="number"
						disabled={!warehouseMappings.has(warehouse.id)}
						value={warehouseMappings.get(warehouse.id)?.warehouse_client_id}
						onChange={(e) => setWarehouseClientID(warehouse.id, e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						type="number"
						disabled={!warehouseMappings.has(warehouse.id)}
						value={warehouseMappings.get(warehouse.id)?.sub_warehouse_id}
						onChange={(e) => setSubWarehouseID(warehouse.id, e.target.value)}
					/>
				</TableCell>
			</TableRow>
		);
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	return (
		<>
			<TableContainer component={Paper}>
				<Table
					aria-label="warehouse mappings table"
					id="warehouse-mappings-table"
					stickyHeader
					size={"small"}
				>
					<TableHead>
						<TableRow>
							<TableCell>Warehouse Name</TableCell>
							<TableCell>Enabled</TableCell>
							<TableCell>Warehouse Client ID</TableCell>
							<TableCell>Sub Warehouse ID</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							(page_data.warehouses) ?
								rows().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((wh, index) =>
									<WarehouseMappingTableRow key={index} warehouse={wh}/>
								) :
								<TableRow>
									<TableCell colSpan={2} align="center">
										No warehouses found
									</TableCell>
								</TableRow>
						}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={rows().length}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[]}
					page={page}
					onPageChange={handleChangePage}
				/>
			</TableContainer>

			<ModalFull
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalBodyTitle={state.modalBodyTitle}
				modalBodyMessage1={state.modalBodyMessage1}
				modalBodyMessage1Data={state.modalBodyMessage1Data}
				modalBodyMessage2={state.modalBodyMessage2}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
				modalButtonVariant2={state.modalButtonVariant2}
				modalButtonText2={state.modalButtonText2}
				modalButtonActionType2={state.modalButtonActionType2}
				modalButtonAction2={state.modalButtonAction2}
				modalButtonColor2={state.modalButtonColor2}
			/>

			<Box className="align-content-right">
				<Button
					variant="outlined"
					color="secondary"
					className="back-button"
					onClick={() => handleBackButton()}
				>
					Back
				</Button>
				<Button
					variant="contained"
					onClick={onSubmitChanges}
				>
					Confirm
				</Button>
			</Box>
		</>
	);
}
