import React, { useState } from "react";
import DataTablePaginated from "../Shared/Table/TablePaginated";
import FileUpload from "./FileUpload";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	Grid,
	Box,
	Paper,
	Typography,
	Link
} from "@mui/material";
import * as tableConstants from "../Shared/Config/TableConfig";

export default function ShippingPricing() {

	const pageRowOptions = tableConstants.PAGE_ROW_OPTIONS;
	const [state, setState] = useState({
		isModalOpen: false,
		currentPageShippingPricing: 1,
		rowsPerPageShippingPricing: pageRowOptions[3],
	});

	const shippingPricingURL = "/v1/pricing/shipping/default?";

	const shippingPricingHeader = [
		"seller",
		"warehouse",
		"courier_code",
		"weight_from",
		"weight_to",
		"origin",
		"destination",
		"insurance",
		"price_ex_vat",
		"fuel_surcharge",
		"customs_surcharge",
		"additional_surcharge",
		"valid_from",
		"valid_to",
		"comment"
	];

	const onChangePageRowShippingPricing = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				rowsPerPageShippingPricing: pageRows,
				currentPageShippingPricing: 1,
			};
		});
	};

	const onModalClose = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
			};
		});
	};

	const onUploadClick = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});
	};

	const ShippingPricingTable = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
				<Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Default Shipping Pricing
				</Typography>

				<DataTablePaginated
					headers={shippingPricingHeader}
					dateHeaders={["valid_from, valid_to"]}
					seller={1} // hardcoded dummy value required for FetchDataIncrementally component to run, as requires seller value
					url={shippingPricingURL}
					currentPage={state.currentPageShippingPricing}
					rowsPerPage={state.rowsPerPageShippingPricing}
					onChangePageRow={onChangePageRowShippingPricing}
					csvFilename={"Shipping_Pricing"}
				/>

			</Paper>
		);
	};

	const BulkUploadPrices = () => {
		return (
			<Dialog
				onClose={onModalClose}
				open={state.isModalOpen}
				keepMounted
				aria-describedby="alert-dialog-slide-description"
				fullWidth={true}
				maxWidth="sm"
			>
				<DialogTitle>Bulk Upload Prices</DialogTitle>
				<DialogContent>
					<FileUpload />
				</DialogContent>
			</Dialog>
		);
	};

	const PageHeading = () => {
		return (
			<>
				<Grid item xs={3} sm={4} lg={10} xl={12} container mb={3}>
					<Grid item xs="auto" mr={1} mb={2}>
						<Typography variant="h5">
                            Shipping Pricing
						</Typography>
					</Grid>
					<Grid item xs />
					<Grid item xs="auto">
						<Button variant="contained" type="button" onClick={onUploadClick}>
                            Bulk Upload Prices
						</Button>
					</Grid>
				</Grid>
				<Typography variant="p" sx={{ marginBottom: 1, fontWeight: "normal" }}>
                    For sellers with custom pricing see retool <Link href="https://bezosai.retool.com/apps/4f67e0a0-d334-11eb-9a65-f771d853139c/Finance/Seller%20Pricing%20-%20Shipping%20Editor" target="_blank" rel="noopener">here</Link>
					<br /> Download default pricing template <Link href="https://docs.google.com/spreadsheets/d/1VtW0dA2r-mjV9uT4OQJOBJYHYvS16uBGDZqQHg5xu_g/edit#gid=1227596768" target="_blank" rel="noopener">here</Link> (fields in bold are required).
				</Typography>
			</>
		);
	};

	return (
		<Box id="shipping-pricing">
			<PageHeading />
			<BulkUploadPrices />
			<ShippingPricingTable />
		</Box>
	);
}
