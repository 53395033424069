import React from "react";
import "./BezosSignIn.scss";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
	Box,
	Button,
	Grid,
	Typography,
	Avatar,
} from "@mui/material";

export default {

	Header() {

		return (
			<Grid
				id="login-page-header"
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
			>

				<Grid item xs={12} lg={12} mb={2}>
					<Avatar
						src={"/logo/bezos-logo.svg"}
						alt="Bezos Operations Portal"
						title="Bezos Operations Portal"
						variant="square"
						sx={{ width: "280px", height: "60px", marginTop: "40px" }}
					>
					</Avatar>
				</Grid>

				<Grid item xs={12} lg={12} mb={2}>
					<Typography variant="h5" component="h2">
						Operations Portal Sign in
					</Typography>
				</Grid>

			</Grid>
		);
		
	},

	Footer() {
		const { toResetPassword } = useAuthenticator();

		return (
			<Grid item xs={12} lg={12} mt={5} mb={3}>
				<Box textAlign="center">
					<Button
						variant="text"
						color="primary"
						type="button"
						onClick={toResetPassword}
					>
						Forgot password?
					</Button>
				</Box>
			</Grid>
		);

	}

};