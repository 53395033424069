import React from "react";
import { LoadingStatusBox } from "../LoadingStatus/LoadingStatus";

export const LoadableContent = (props) => {

	return (props.isLoading) ? 
		<LoadingStatusBox message={props.message || "Loading..."} marginBottom={props.marginBottom || 4} sx={ props.sx } /> 
		: 
		<> {props.children} </>;

};