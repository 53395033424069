import React, { useState } from "react";
import * as Yup from "yup";
import {
	Form,
	Formik
} from "formik";
import { TextfieldFormik } from "../../Shared/TextField/TextField";
import {
	Box,
	Button
} from "@mui/material";
import { DataContext } from "../../../App";
import Modal from "../../Shared/Modal/Modal";
import { handleAxiosError } from "../../../utils/error";
import { useNavigate } from "react-router-dom";
import { deleteKeyFromObject } from "../../../utils/object";

export function ContactDetails({
	summaryDetailsForm,
	additionalOptionsForm,
	onPanelsVisibleChange,
	existingWarehouse
}) {

	const navigate = useNavigate();
	const context = React.useContext(DataContext);
	const [state, setState] = useState({
		isFormSubmitted: false,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalBodyInputFields: {},
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
		modalIsLoading: false,
	});

	const INITIAL_FORM_STATE = {
		contact_name: existingWarehouse.contact_name ?? "",
		contact_email: existingWarehouse.contact_email ?? "",
		ops_support_contact: existingWarehouse.ops_support_contact ?? "",
		zohodesk_contact: existingWarehouse.zohodesk_contact ?? "",
	};

	const FORM_VALIDATION = Yup.object().shape({
		contact_name: Yup.string(),
		contact_email: Yup.string()
			.email("Contact email address should be formatted correctly"),
		ops_support_contact: Yup.string()
			.email("Contact email address should be formatted correctly e.g. ops@domain.com"),
		zohodesk_contact: Yup.string()
			.email("Zohodesk email address should be formatted correctly e.g. zoho@domain.com"),
	});

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
				modalBodyInputFields: {},
			};
		});
	};

	const handleBackButton = () => {
		onPanelsVisibleChange({
			isSummaryDetailsPanelVisible: false,
			isAdditionalOptionsPanelVisible: true,
			isContactDetailsPanelVisible: false,
		});
	};

	const successPageRedirect = () => {
		const timer = setTimeout(() => {
			navigate("/warehouse-configuration");
		}, 5000);
		return () => clearTimeout(timer);
	};

	const createOrUpdateWarehouse = async (warehouse) => {
		try {
			setState(previousState => {
				return {
					...previousState,
					isFormSubmitted: true,
					modalIsLoading: true,
					isModalOpen: true,
				};
			});

			let response;
			if (existingWarehouse.id) {
				response = await context.dataProvider.updateWarehouse(existingWarehouse.id, warehouse);
			} else {
				let updatedWarehouse = deleteKeyFromObject(warehouse, "id");
				updatedWarehouse = deleteKeyFromObject(updatedWarehouse, "created_at");
				response = await context.dataProvider.createWarehouse(updatedWarehouse);
			}

			if (!response) {
				return setState(previousState => {
					return {
						...previousState,
						modalIsLoading: false,
						modalBodyTitle: existingWarehouse.id ? "Warehouse Updated Error" : "Warehouse Creation Error",
						modalBodyMessage1: existingWarehouse.id ? `There was an error trying to update warehouse "${existingWarehouse.name}", please try again.` : "There was an error trying to create the new warehouse.",
						modalBodyMessage2: "Unknown Error, please contact Engineering",
						modalButtonVariant1: "contained",
						modalButtonText1: "Return to Warehouse",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
						isFormSubmitted: false,
					};
				});
			}

			setState(previousState => {
				return {
					...previousState,
					modalIsLoading: false,
					modalBodyTitle: existingWarehouse.id ? "Warehouse Updated" : "Warehouse Created",
					modalBodyMessage1: existingWarehouse.id ? `You have successfully updated warehouse "${existingWarehouse.name}".` :
						"You have successfully created the warehouse.",
					modalBodyMessage2: "",
					modalButtonVariant1: "contained",
					modalButtonText1: "Return to Warehouse Summary page",
					modalButtonActionType1: "link",
					modalButtonAction1: "/warehouse-configuration",
				};
			});

			successPageRedirect();

		} catch (error) {
			setState(previousState => {
				return {
					...previousState,
					modalIsLoading: false,
					modalBodyTitle: existingWarehouse.id ? "Warehouse Updated Error" : "Warehouse Creation Error",
					modalBodyMessage1: existingWarehouse.id ? `There was an error trying to update warehouse "${existingWarehouse.name}", please try again.` : "There was an error trying to create the new warehouse.",
					modalBodyMessage2: `Error: ${handleAxiosError(error)}`,
					modalButtonVariant1: "contained",
					modalButtonText1: "Return to Warehouse",
					modalButtonActionType1: "close",
					modalButtonAction1: "",
					isFormSubmitted: false,
				};
			});
		}
	};

	const onSubmitForm = (values) => {
		setState(previousState => {
			return {
				...previousState,
				isFormSubmitted: true,
			};
		});

		const allFormData = {
			...summaryDetailsForm,
			...additionalOptionsForm,
			...values
		};

		createOrUpdateWarehouse(allFormData);
	};

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={onSubmitForm}
			>
				{() => (
					
					<Form
						noValidate
						autoComplete="off"
					>

						<TextfieldFormik
							name="contact_name"
							label="Contact Name"
						/>

						<TextfieldFormik
							name="contact_email"
							label="Contact Email"
							type="email"
						/>

						<TextfieldFormik
							name="ops_support_contact"
							label="Ops Support Contact"
							type="email"
						/>

						<TextfieldFormik
							name="zohodesk_contact"
							label="Zohodesk Contact"
							type="email"
						/>

						<Box className="align-content-right">
							<Button
								variant="outlined"
								color="secondary"
								className="back-button"
								onClick={() => handleBackButton()}
								disabled={state.isFormSubmitted}
							>
								Back
							</Button>

							<Button
								type="submit"
								variant="contained"
								disabled={state.isFormSubmitted}
							>
								{existingWarehouse.id ? "Update Warehouse" : "Create Warehouse"
								}
							</Button>
						</Box>

					</Form>
				)}
			</Formik>
			<Modal
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalBodyTitle={state.modalBodyTitle}
				modalBodyMessage1={state.modalBodyMessage1}
				modalBodyMessage1Data={state.modalBodyMessage1Data}
				modalBodyMessage2={state.modalBodyMessage2}
				modalBodyInputFields={state.modalBodyInputFields}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
				modalButtonVariant2={state.modalButtonVariant2}
				modalButtonText2={state.modalButtonText2}
				modalButtonActionType2={state.modalButtonActionType2}
				modalButtonAction2={state.modalButtonAction2}
				modalButtonColor2={state.modalButtonColor2}
				modalIsLoading={state.modalIsLoading}
			/>
		</>
	);
}