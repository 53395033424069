export const formatNumber = (input) => {

	if (typeof input !== "number") return input;

	return input.toLocaleString();

};

export const convertToPercentage = (input) => {

	if (typeof input !== "number") return input;
  
	const percentage = (input * 100).toFixed(1);
	const formattedPercentage = parseFloat(percentage).toString();
  
	return formattedPercentage + "%";

};
