import React, {
	useState,
	useContext,
	useCallback,
	useMemo
} from "react";
import "./DeliverySLAChecker.scss";
import useFetchData from "../Shared/FetchData/FetchData";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import {
	getCurrentDateTruncated,
	getFormattedStartTime,
	getFormattedEndTime
} from "../../utils/date.js";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import { ViewButtonMultiLinks } from "./components/ViewButtonMultiLinks";
import {
	Box,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { DataContext } from "../../App";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function DeliverySLAChecker() {

	const context = useContext(DataContext);
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 5);

	const [state, setState] = useState({
		startDate: initStartDate,
		endDate: initEndDate,
	});

	const standardParams = useMemo(() => new URLSearchParams({
		start_time: getFormattedStartTime(state.startDate),
		end_time: getFormattedEndTime(state.endDate),
		sla_days: 999,
		variance: 2
	}), [state.startDate, state.endDate]);

	const standardDeliveryData = useFetchData(useCallback(() =>
		context.dataProvider.getDeliverySLA(standardParams),
	[context.dataProvider, standardParams]));

	const nextDayParams = useMemo(() => new URLSearchParams({
		start_time: getFormattedStartTime(state.startDate),
		end_time: getFormattedEndTime(state.endDate),
		sla_days: 1,
		variance: 1
	}), [state.startDate, state.endDate]);

	const nextDayDeliveryData = useFetchData(useCallback(() =>
		context.dataProvider.getDeliverySLA(nextDayParams),
	[context.dataProvider, nextDayParams]));

	const deliverySLATypes = useFetchData(useCallback(() =>
		context.dataProvider.getDeliverySLATypes(),
	[context.dataProvider]));

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue
			};
		});
	};

	const HeadingContent = () => (
		<>
			<Grid item xs />
			<Grid item xs="auto">
				<Grid item xs="auto" sx={{ mt:1 }}>
					<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
					<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
				</Grid>
			</Grid>
		</>
	);

	const TableHeader = () => (
		<TableHead>
			<TableRow>
				<TableCell className="table-header">Order number</TableCell>
				<TableCell className="table-header">Status</TableCell>
				<TableCell className="table-header">Courier service</TableCell>
				<TableCell className="table-header">Tracking number</TableCell>
				<TableCell className="table-header">SLA</TableCell>
				<TableCell className="table-header">Days in transit</TableCell>
				<TableCell className="table-header">Variance</TableCell>
				<TableCell className="table-header">Seller</TableCell>
				<TableCell className="table-header">Last checkpoint</TableCell>
				<TableCell className="table-header">Email sent</TableCell>
				<TableCell className="table-header"></TableCell>
			</TableRow>
		</TableHead>
	);

	const TableData = ({ data }) => {
		if (data.length > 0) {
			return (
				data.map((item, index) =>
					<TableRow key={index}>
						<TableCell>{item.order_number}</TableCell>
						<TableCell>
							<StatusBox status={item.status} statusType="courier" />
						</TableCell>
						<TableCell>{item.courier_service}</TableCell>
						<TableCell>{item.tracking_number}</TableCell>
						<TableCell>{item.sla}</TableCell>
						<TableCell>{item.days_in_transit}</TableCell>
						<TableCell>{item.variance}</TableCell>
						<TableCell>{item.client_name}</TableCell>
						<TableCell>{item.last_checkpoint_message}</TableCell>
						<TableCell>
							<StatusBox status={item.email_sent === true ? "Yes" : "No"} statusType="email" />
						</TableCell>
						<TableCell>
							<ViewButtonMultiLinks
								linkData={deliverySLATypes.results}
								order={item.order_number}
								seller={item.client_name}
								email={item.contact}
							/>
						</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="12" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}
	};

	const DeliveryDataTable = ({ data, title }) => (
		<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
			<Typography variant="h5">{title}</Typography>
			<LoadableContent isLoading={data.isLoading}>
				<Typography variant="h5" sx={{ marginBottom: 4 }}>
					<RecordsTotal total={data.results.length} />
				</Typography>
				<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
					<Table stickyHeader>
						<TableHeader />
						<TableBody>
							<TableData data={data.results} />
						</TableBody>
					</Table>
				</TableContainer>
			</LoadableContent>
		</Paper>
	);

	return (
		<Box id="delivery-sla-checker">
			<PageHeading pageTitle={"Delivery SLA Checker"}>
				<HeadingContent/>
			</PageHeading>
			<DeliveryDataTable data={standardDeliveryData} title="Standard Delivery" />
			<DeliveryDataTable data={nextDayDeliveryData} title="Next Day Delivery" />
		</Box>
	);
}
