import React, {
	useContext,
	useCallback,
	useState,
	useEffect,
	useRef,
} from "react";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Button,
	MenuItem,
} from "@mui/material";
import { DataContext } from "../../App";
import useFetchData from "../Shared/FetchData/FetchData";
import DataTable from "../Shared/Table/Table";
import Modal from "../Shared/Modal/Modal";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/EditLocationAlt";
import DropDown from "../Shared/DropDown/DropDown";
import { excludeById } from "../../utils/array";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import AddIcon from "@mui/icons-material/Add";
import { handleAxiosError } from "../../utils/error";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function CourierConfiguration() {

	const selectedAreaRef = useRef(null);
	const selectedCourierRef = useRef(null);
	const context = useContext(DataContext);
	const [state, setState] = useState({
		isLoading: false,
		reloadTable: false,
		isModalOpen: false,
		courierServiceToAreas: null,
		resultsTotal: 0,
	});

	useEffect(() => {

		setState(previousState => {
			return {
				...previousState,
				isLoading: true
			};
		});

		const getCouriers = () => {
			context.dataProvider.getCourierServiceToAreas()
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							isLoading: false,
							courierServiceToAreas: res.data,
							resultsTotal: res.data.length,
						};
					});
				});

		};
		getCouriers();
	}, [state.reloadTable]);

	const couriers = useFetchData(useCallback(() => context.dataProvider.getBezosCouriers(), [context.dataProvider]));
	const serviceAreas = useFetchData(useCallback(() => context.dataProvider.getServiceAreas(), [context.dataProvider]));

	const [transformedData, setTransformedData] = useState([]);

	useEffect(() => {
		if (state.courierServiceToAreas && couriers.results && serviceAreas.results) {
			const withNames = state.courierServiceToAreas.map(serviceArea => {
				const courierName = couriers.results.find(courier => courier.id === serviceArea.courier_service_id)?.name || "Unknown";
				const areaName = serviceAreas.results.find(area => area.id === serviceArea.area_id)?.name || "Unknown";

				return {
					...serviceArea,
					courier_name: courierName,
					area_name: areaName,
				};
			});
			setTransformedData(withNames);
		}
	}, [state.courierServiceToAreas, couriers.results, serviceAreas.results]);

	const tableHeaders = [
		{
			key: "courier_name",
			label: "Courier Name",
		},
		{
			key: "area_name",
			label: "Area Name",
		},
		{
			key: "custom_component1",
			label: "",
			component: (rowData) => <DeleteButton data={rowData} />,
		},
		{
			key: "custom_component2",
			label: "",
			component: (rowData) => <EditButton data={rowData} />,
		},		
	]; 

	const onClickDeleteCourier = (id, courierServiceID, areaID, courierName) => {

		if (id === "", courierServiceID === "", areaID === "", courierName === "") return;

		setState(previousState => {
			return {
				...previousState,			
				isModalOpen: true,
			};
		});

		context.dataProvider.deleteCourierServiceToArea(id, courierServiceID, areaID)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						isModalOpen: true,
						modalIsLoading: false,
						modalBodyTitle: "Courier Service Deleted",
						modalBodyMessage1: `Courier ${courierName} has been deleted.`,
						modalBodyMessage2: "",
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
						reloadTable: !state.reloadTable,
					};
				});
			})
			.catch((error) => {
				setState(previousState => {
					return {
						...previousState,
						isModalOpen: true,
						modalIsLoading: false,
						modalBodyTitle: "Error Deleting Courier",
						modalBodyMessage1: `There was an error trying to delete the courier ${courierName}.`,
						modalBodyMessage2: `Error: ${handleAxiosError(error)}`,
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				modalBodyMessage1Data: null,
				modalButtonVariant2: "",
				modalButtonText2: "",
				modalButtonActionType2: "",				
				isModalOpen: true,
			};
		});

	};

	const onClickDeleteCourierModal = ({ data }) => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Delete Courier Service",
				modalBodyMessage1: `Are you sure you want to delete the courier service ${data.courier_name}?`,
				modalBodyMessage1Data: [],
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "Cancel",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalButtonVariant2: "contained",
				modalButtonText2: "Delete",
				modalButtonActionType2: "function",
				modalButtonAction2: () => onClickDeleteCourier(data.id, data.courier_service_id, data.area_id, data.courier_name),
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const DeleteButton = ({ data }) => (
		<Button 
			title="Delete Courier Service"
			variant="contained"
			color="destructive"
			onClick={() => onClickDeleteCourierModal(data)}
		>
			<DeleteIcon />
		</Button>
	);

	const handleAreaChange = (selectedAreaId) => {
		selectedAreaRef.current = selectedAreaId;
	};

	const AreasDropDown = (id) => {

		const renderMenuItems = (areas, excludeId) => {

			const filteredAreas = excludeId ? excludeById(areas, excludeId) : areas;
			
			return filteredAreas.map((area) => (
				<MenuItem 
					key={area.id} 
					value={area.id}
				>
					{area.name}
				</MenuItem>
			));
		};
		
		let options;
		
		options = id ? 
			renderMenuItems(serviceAreas.results, id.id) 
			: 
			renderMenuItems(serviceAreas.results);

		return (
			<DropDown
				label={"Area"}
				id={"select_area"}
				onChangeSelect={handleAreaChange}
				sx={{ minWidth: "120px", mt: 2 }}
				size="small"
				map={options}
			/>
		);

	};

	const onClickEditCourier = async (id, serviceID, courierName) => {

		const courierAreaId = selectedAreaRef.current;

		if (id === "" || serviceID === "" || courierName === "" || courierAreaId === "") return;

		setState(previousState => {
			return {
				...previousState,			
				isModalOpen: true,
			};

		});

		await context.dataProvider.putCourierServiceToArea(id, serviceID, courierAreaId)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						isModalOpen: true,
						modalIsLoading: false,
						modalBodyTitle: "Courier Area Updated",
						modalBodyMessage1: `Area for Courier ${courierName} updated.`,
						modalBodyMessage2: "",
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
						reloadTable: !state.reloadTable,
					};
				});
			})
			.catch((error) => {
				setState(previousState => {
					return {
						...previousState,
						isModalOpen: true,
						modalIsLoading: false,
						modalBodyTitle: "Error Updating Courier Area",
						modalBodyMessage1: `There was an error trying to update the area for courier ${courierName}.`,
						modalBodyMessage2: `Error: ${handleAxiosError(error)}`,
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
					};
				});
			});
			
		setState(previousState => {
			return {
				...previousState,
				modalBodyMessage1Data: null,
				modalButtonVariant2: "",
				modalButtonText2: "",
				modalButtonActionType2: "",				
				isModalOpen: true,
			};
		});

	};

	const onClickEditCourierModal = ({ data }) => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Edit Courier Service Area",
				modalBodyMessage1: `Change area for courier ${data.courier_name}?`,
				modalBodyMessage1Data: <AreasDropDown id={data.area_id} />,
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "Cancel",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalButtonVariant2: "contained",
				modalButtonText2: "Change Area",
				modalButtonActionType2: "function",
				modalButtonAction2: () => onClickEditCourier(data.id, data.courier_service_id, data.courier_name),
				modalButtonColor2: "destructive",
				isModalOpen: true,
			};
		});
	};

	const EditButton = ({ data }) => (
		<Button 
			title="Edit Courier Service Area"
			variant="contained"
			color="info"
			onClick={() => onClickEditCourierModal(data)}
		>
			<EditIcon />
		</Button>
	);

	const handleCourierChange = (selectedCourierId) => {
		selectedCourierRef.current = selectedCourierId;
	};

	const CouriersDropDown = () => {
		const options = couriers.results.map(courier => (
			<MenuItem 
				key={courier.id}
				value={courier.id}
			>
				{courier.name}
			</MenuItem>
		));

		return (
			<DropDown
				label={"Courier Service"}
				id={"select_courier"}
				onChangeSelect={handleCourierChange}
				sx={{ minWidth: "200px", mt: 2, mr: 2 }}
				size="small"
				map={options}
			/>
		);
	};

	const onClickAddCourier = async () => {

		const courierId = selectedCourierRef.current;
		const courierAreaId = selectedAreaRef.current;

		if (courierId === "" || courierAreaId === "") return;

		const courierName = couriers.results.find(courier => courier.id === courierId)?.name || "Unknown Courier";
		const courierAreaName = serviceAreas.results.find(area => area.id === courierAreaId)?.name || "Unknown Area";

		setState(previousState => {
			return {
				...previousState,			
				isModalOpen: true,
			};

		});

		await context.dataProvider.postCourierServiceToArea(1, courierId, courierAreaId)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						isModalOpen: true,
						modalIsLoading: false,
						modalBodyTitle: "Courier Service Added",
						modalBodyMessage1: `The new courier service "${courierName}: ${courierAreaName}" has been added.`,
						modalBodyMessage2: "",
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
						reloadTable: !state.reloadTable,
					};
				});
			})
			.catch((error) => {
				setState(previousState => {
					return {
						...previousState,
						isModalOpen: true,
						modalIsLoading: false,
						modalBodyTitle: "Error Adding Courier Service",
						modalBodyMessage1: `There was an error trying to add "${courierName}: ${courierAreaName}" courier service.`,
						modalBodyMessage2: `Error: ${handleAxiosError(error)}`,
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				modalBodyMessage1Data: null,
				modalButtonVariant2: "",
				modalButtonText2: "",
				modalButtonActionType2: "",				
				isModalOpen: true,
			};
		});

	};

	const onClickAddCourierModal = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Add Courier",
				modalBodyMessage1: "Select the courier service and area for the new courier.",
				modalBodyMessage1Data: [
					<CouriersDropDown key="couriersDropDown" />,
					<AreasDropDown key="areasDropDown" />
				],
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "Cancel",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalButtonVariant2: "contained",
				modalButtonText2: "Confirm",
				modalButtonActionType2: "function",
				modalButtonAction2: () => onClickAddCourier(),
				modalButtonColor2: "primary",
				isModalOpen: true,
			};
		});
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	const HeadingContent = () => (
		<>
			<Grid item xs />
			<Grid item xs="auto">
				<Button 
					title="Add Courier"
					variant="contained"
					onClick={() => onClickAddCourierModal()}
				>
					Add Courier <AddIcon />
				</Button>
			</Grid>
		</>
	);

	const PageBody = () => (
		<>
			<Typography variant="h5" mb={2}>
				<RecordsTotal total={state.resultsTotal} sx={{ mb: 2 }}/>
			</Typography>
			<DataTable
				data={transformedData.sort((a, b) => a.id > b.id ? -1 : 1)}
				headers={tableHeaders}
				dateHeaders={[]}
				isLoading={state.isLoading}
				usePagination={false}
			/>
		</>
	);

	return (
		<Box id="courier-configuration">
			<Grid container>
				<PageHeading pageTitle={"Courier Configuration"} sx={{ mb:2 }}>
					<HeadingContent/>
				</PageHeading>
				<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
					<PageBody />
				</Paper>
				<Modal
					onModalClose={onModalClose}
					isModalOpen={state.isModalOpen}
					modalBodyTitle={state.modalBodyTitle}
					modalBodyMessage1={state.modalBodyMessage1}
					modalBodyMessage1Data={state.modalBodyMessage1Data}
					modalBodyMessage2={state.modalBodyMessage2}
					modalButtonVariant1={state.modalButtonVariant1}
					modalButtonText1={state.modalButtonText1}
					modalButtonActionType1={state.modalButtonActionType1}
					modalButtonAction1={state.modalButtonAction1}
					modalButtonVariant2={state.modalButtonVariant2}
					modalButtonText2={state.modalButtonText2}
					modalButtonActionType2={state.modalButtonActionType2}
					modalButtonAction2={state.modalButtonAction2}
					modalButtonColor2={state.modalButtonColor2}
				/>
			</Grid>
		</Box>
	);
}