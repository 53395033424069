import {
	useEffect,
	useState
} from "react";

const useFetchData = ( getter ) => {

	const [results, setResults] = useState([]);
	const [status, setStatus] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {


		const controller = new AbortController();
		let mounted = true;

		const fetchData = async () => {

			if (!getter || !mounted) return;

			setIsLoading(true);

			try {
				await getter()
					.then((response) => {
						if (!response.data || response.status !== 200) return;
						setResults(response.data);
						setStatus(true);
					});
			} catch (e) {
				setStatus(false);
			}

			setIsLoading(false);
		};
		fetchData();

		return () => {
			mounted = false;
			controller.abort();
		};

	}, [getter]);

	return {
		status, results, isLoading
	};
};

export default useFetchData;
