import React, { 
	useState, 
	useEffect 
} from "react";
import {
	Box,
	Grid,
	Typography,
	Paper,
} from "@mui/material";
import { 
	useLocation, 
	useNavigate,
} from "react-router-dom";
import { setDocumentTitle } from "../../utils/page";
import { SummaryDetails } from "./views/SummaryDetails";
import { AdditionalOptions } from "./views/AdditionalOptions";
import { ContactDetails } from "./views/ContactDetails";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";

export default function WarehouseCreate() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const existingWarehouse = state?.warehouse?.[0] ?? {};

	const [pageState, setPageState] = useState({
		summaryDetailsForm: {},
		additionalOptionsForm: {},
		panelsVisible: {
			isSummaryDetailsPanelVisible: true,
			isAdditionalOptionsPanelVisible: false,
			isContactDetailsPanelVisible: false,
		},
	});

	const pageName = Object.keys(existingWarehouse).length > 0 ? "Edit Warehouse" : "Create Warehouse";

	useEffect(() => {
		setDocumentTitle(pageName);
	}, [existingWarehouse]);

	const PageHeading = () => (
		<Grid container mb={1}>
			<Grid item xs="auto">
				<Typography component="h1" variant="h5">
					{pageName}
				</Typography>
			</Grid>
		</Grid>
	);

	const onPanelsVisibleChange = (props) => {
		setPageState(previousState => {
			return {
				...previousState,
				panelsVisible: props,
			};
		});
	};

	const onSummaryDetailsFormSubmit = (props) => {
		setPageState(previousState => {
			return {
				...previousState,
				summaryDetailsForm: props,
			};
		});
	};

	const onAdditionalOptionsFormSubmit = (props) => {
		setPageState(previousState => {
			return {
				...previousState,
				additionalOptionsForm: props,
			};
		});
	};

	return (
		<Box id="warehouse-create">
			<Grid container>
				<BreadCrumb 
					breadcrumbs={[
						{ label: "Warehouse Configuration", url: "/warehouse-configuration" },
						{ label: existingWarehouse?.name, url: () => navigate(-1) },
						{ label: pageName }
					]}
				/>
				<PageHeading />
				<Box>
					<Grid container spacing={1}>

						<Box className={`panel${pageState.panelsVisible.isSummaryDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
							<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
								<Typography component="h3" variant="h6">1. Add Summary Details</Typography>
								<Box className={`panel${pageState.panelsVisible.isSummaryDetailsPanelVisible === true ? "-show" : "-hide"}`}>
									<SummaryDetails
										onSummaryDetailsFormSubmit={onSummaryDetailsFormSubmit}
										onPanelsVisibleChange={onPanelsVisibleChange}
										existingWarehouse={existingWarehouse}
									/>
								</Box>
							</Paper>
						</Box>

						<Box className={`panel${pageState.panelsVisible.isAdditionalOptionsPanelVisible === true ? "-active" : "-disabled"}`}>
							<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
								<Typography component="h3" variant="h6">2. Add Additional Options</Typography>
								<Box className={`panel${pageState.panelsVisible.isAdditionalOptionsPanelVisible === true ? "-show" : "-hide"}`}>
									<AdditionalOptions
										onAdditionalOptionsFormSubmit={onAdditionalOptionsFormSubmit}
										onPanelsVisibleChange={onPanelsVisibleChange}
										existingWarehouse={existingWarehouse}
									/>
								</Box>
							</Paper>
						</Box>

						<Box className={`panel${pageState.panelsVisible.isContactDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
							<Paper sx={{ p: 2, width: "100%", marginY: 1 }} elevation={2}>
								<Typography component="h3" variant="h6">3. Add Contact Details</Typography>
								<Box className={`panel${pageState.panelsVisible.isContactDetailsPanelVisible === true ? "-show" : "-hide"}`}>
									<ContactDetails
										summaryDetailsForm={pageState.summaryDetailsForm}
										additionalOptionsForm={pageState.additionalOptionsForm}
										onPanelsVisibleChange={onPanelsVisibleChange}
										existingWarehouse={existingWarehouse}
									/>
								</Box>
							</Paper>
						</Box>

					</Grid>
				</Box>
			</Grid>
		</Box>
	);

}
