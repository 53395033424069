import React, {
	useContext,
	useCallback,
} from "react";
import {
	Box,
	Grid,
	Typography,
	Paper,
} from "@mui/material";
import MediaCard from "../Shared/Cards/MediaCard";
import BarChartCard from "../Shared/Cards/BarChartCard";
import useFetchData from "../Shared/FetchData/FetchData";
import { DataContext } from "../../App";
import {
	formatSeconds,
	getFormattedTime
} from "../../utils/time";
import { capitalizeFirstLetterOfEachWord } from "../../utils/text";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function QueueLatency() {

	const context = useContext(DataContext);
	const queueStats = useFetchData(useCallback(() =>
		context.dataProvider.getQueueStatistics(),
	[context.dataProvider]));
	const now = new Date();

	return (
		<Box id="queue-latency">
			<PageHeading pageTitle={"Queue Statistics"} sx={{ mb: 1 }}/>
			
			<Box sx={{ width: "100%" }}>

				{queueStats && queueStats.results && queueStats.results.map((item, i) => 
					<Paper sx={{ p: 2, mb: 2, width: "100%" }} elevation={2} key={i}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h6">
									{capitalizeFirstLetterOfEachWord(item.name.replaceAll("-"," "))}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={3} lg={3}>
								<MediaCard
									titleTextLong="Queue latency over the previous 5 minutes"
									titleTextShort="Latency"
									bodyText={formatSeconds(item.current_latency)}
									imageName="latency"
									imageHeight="75"
									headingVariant="h6"
									bodyVariant="h6"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3} lg={3}>
								<MediaCard
									titleTextLong="Queue throughput (total messages) over the previous hour"
									titleTextShort="Throughput"
									bodyText={item.throughput_last_hour.toLocaleString() + " messages"}
									imageName="throughput"
									imageHeight="75"
									headingVariant="h6"
									bodyVariant="h6"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3} lg={3}>
								<BarChartCard
									titleTextShort="Latency (s)"
									chartData={item.latency_history.toReversed()}
									labels={item.latency_history.map((_, idx) => idx).toReversed().map((idx) => getFormattedTime(new Date(now - (idx * 60000 * 5))))}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3} lg={3}>
								<BarChartCard
									titleTextShort="Throughput (messages)"
									chartData={item.throughput_history.toReversed()}
									labels={item.throughput_history.map((_, idx) => idx).toReversed().map((idx) => getFormattedTime(new Date(now - (idx * 60000 * 60))))}
								/>
							</Grid>
						</Grid>
					</Paper>
				)}

			</Box>
		</Box>
	);
}