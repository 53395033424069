import React, {
	useState,
	useRef,
	useContext
} from "react";
import {
	Box,
	Button,
	DialogContentText,
	Grid,
	Typography
} from "@mui/material";
import "./FileUpload.scss";
import { DataContext } from "../../App";
import { DeleteButton } from "../Shared/Buttons/Buttons";

export default function FileUpload() {

	const context = useContext(DataContext);
	const uploadInputRef = useRef();
	const [state, setState] = useState({
		filesUploaded: false,
		formSubmitted: false,
		selectedFiles: null,
		isUploadingFiles: false,
		uploadResponseErrors: null,
	});

	const isUploadButtonDisabled = () => {
		if (state.filesUploaded === true
            && state.formSubmitted === false) {
			return false;
		} else {
			return true;
		}
	};

	const uploadTemplateFile = async (file) => {

		const url = "/v1/pricing/shipping/default";
		const postBodyData = new FormData();
		postBodyData.append("File", file);

		return context.dataProvider.uploadFile(url, postBodyData);

	};

	const onChangeGetUploadFile = (e) => {
		setState(previousState => ({
			...previousState,
			selectedFiles: e.target.files,
			filesUploaded: true,
			formSubmitted: false,
			uploadResponseErrors: null,
			uploadResponseSuccesses: null
		}));
	};

	const onDeleteUploadFile = () => {
		uploadInputRef.current.value = "";
		setState(previousState => {
			return {
				...previousState,
				selectedFiles: null,
				filesUploaded: false,
			};
		});
	};

	const onClickUploadFile = async () => {
		setState(previousState => {
			return {
				...previousState,
				isUploadingFiles: true,
			};
		});

		for (const file of state.selectedFiles) {
			try {
				const res = await uploadTemplateFile(file);
				setState(previousState => ({
					...previousState,
					uploadResponseSuccesses: res.data["successes"],
					uploadResponseErrors: res.data["errors"]
				}));
			} catch (error) {
				let responseErrors = [];
				if (error.response.data && error.response.data["errors"]) {
					responseErrors.push(error.response.data["errors"]);
				} else if (error.response.data) {
					responseErrors.push(error.response.data);
				} else {
					responseErrors.push("no further information was available");
				}

				setState(previousState => ({
					...previousState,
					uploadResponseErrors: [error.message, ...responseErrors]
				}));
			}
		}

		setState(previousState => ({
			...previousState,
			isUploadingFiles: false,
			filesUploaded: false,
		}));
		uploadInputRef.current.value = "";
	};

	return (
		<Box id="file-upload">
			<Grid item xs={12} md={6} lg={6}>
				<Box mb={1}>
					<input
						type="file"
						name="File Upload"
						accept='text/csv'
						aria-label="Upload CSV"
						ref={uploadInputRef}
						onChange={(e) => onChangeGetUploadFile(e)}
					/>
					{(state.filesUploaded === true) &&
						<Button title="Remove file">
							<DeleteButton
								onClick={() => onDeleteUploadFile()}
								icon={true}
							/>
						</Button>
					}
				</Box>
				<Typography mb={2} sx={{ fontStyle: "italic", fontSize: "0.75rem" }}>
                    Only upload CSV files
				</Typography>

				<Box>
					<Button
						variant="contained"
						component="span"
						onClick={(e) => onClickUploadFile(e)}
						disabled={isUploadButtonDisabled()}
					>
                        Upload
					</Button>
					{state.uploadResponseErrors != null &&
						<DialogContentText mt={2} id="alert-dialog-slide-description" component="div">
							<Typography>Errors:</Typography>
							<ul>
								{(state.uploadResponseErrors.map((item, index) => (
									<li key={index}>
										{item}
									</li>
								)))}
							</ul>
						</DialogContentText>
					}
					{state.uploadResponseSuccesses != null &&
						<DialogContentText mt={2} id="alert-dialog-slide-description" component="div">
							<Typography>Successfully Uploaded:</Typography>
							<ul>
								{(state.uploadResponseSuccesses.map((item, index) => (
									<li key={index}>
										{item}
									</li>
								)))}
							</ul>
						</DialogContentText>
					}

				</Box>
			</Grid>
		</Box>
	);

}