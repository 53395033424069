import React from "react";
import {
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { LoadableContent } from "../../Shared/LoadableContent/LoadableContent";
import { StatusBox } from "../../Shared/StatusBox/StatusBox";
import { getWarehouse } from "../../../utils/getWarehouse";
import { getEmailStatus } from "../../../utils/getEmailStatus";
import {
	getFormattedStartTime,
	getFormattedEndTime
} from "../../../utils/date.js";
import Modal from "../../Shared/Modal/ModalEmail";

export const EmailTable = ({
	tableTitle,
	tableData,
	warehouseData,
	warehouseDataIsLoading,
	startDate,
	endDate
}) => {

	const url = "/v1/collection/email?";
	const getParams = (item) => {
		const params = new URLSearchParams();
		params.append("start_time", getFormattedStartTime(startDate));
		params.append("end_time", getFormattedEndTime(endDate));
		params.append("warehouse_id", item.warehouse_id);
		return params.toString();
	};

	return (
		<Paper sx={{ p: 2, width: "100%", marginTop: 5 }} elevation={2}>

			<LoadableContent isLoading={warehouseDataIsLoading} marginBottom={4} >

				<Typography variant="h6" sx={{ marginBottom: 4 }}>
					{tableTitle}
				</Typography>
				<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell className="table-header">Warehouse</TableCell>
								<TableCell className="table-header">Number of orders</TableCell>
								<TableCell className="table-header">Warehouse contact email</TableCell>
								<TableCell className="table-header">Warehouse contact status</TableCell>
								<TableCell className="table-header"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableData.length > 0 ?
								(
									tableData.map((item, index) =>
										<TableRow key={index}>
											<TableCell>{getWarehouse(item.warehouse_id, warehouseData)}</TableCell>
											<TableCell>{item.order_count}</TableCell>
											<TableCell>{item.warehouse_contact}</TableCell>
											<TableCell>
												<StatusBox
													status={getEmailStatus(item.orders_not_emailed, item.order_count)}
													statusType={"email"}
												/>
											</TableCell>
											<TableCell>
												{getEmailStatus(item.orders_not_emailed, item.order_count) !== "contacted" &&
													<Modal
														buttonName={"send email"}
														buttonType={"outlined"}
														messageTitle={"Confirm collection email"}
														messageBody={`Please confirm you wish to send ${getWarehouse(item.warehouse_id, warehouseData)} an email to confirm collection of orders?`}
														url={url}
														params={getParams(item)}
													/>
												}
											</TableCell>
										</TableRow>)
								)
								:
								(
									<TableRow>
										<TableCell colSpan="5" className='table-error-message-text'>No results found</TableCell>
									</TableRow>
								)
							}
						</TableBody>
					</Table>
				</TableContainer>
			</LoadableContent>
		</Paper>);
};