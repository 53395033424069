export const getWarehouse = (id, warehouseData) => {

	if (id === "" || id === undefined) {
		return "";
	}

	const addObjectFromWarehouseEndPoint = warehouseData.find(obj => obj.id === Number(id));

	if (addObjectFromWarehouseEndPoint === undefined) {
		return "";
	}

	return (
		addObjectFromWarehouseEndPoint.name
	);

};