export const getKeyValue = (obj, searchKey, keyValue, targetKey) => {
	
	if (!obj || !Array.isArray(obj) || obj.length === 0 || !searchKey || !keyValue || !targetKey) return;
	const item = obj.find(item => item[searchKey] === keyValue);
    
	return item ? item[targetKey] : "";

};

export const filterByKeyValue = (data, key, value) => {
	if (!Array.isArray(data) || data.length === 0) return [];
	if (typeof key !== "string") return [];
	const filteredData = data.filter(item => item[key] === value);
	return filteredData;
};

export const deleteKeyFromObject = (obj, key) => {
	const newObj = { ...obj };
	delete newObj[key];
	return newObj;
};

export const extractIDs = (data) => {
	if (!Array.isArray(data) || data.length === 0) return [];
	const ids = data.map(item => item.id);
	return ids;
};
