import React from "react";
import { Box } from "@mui/material";
import {
	getStatusBorder,
	getStatusColour,
	getOrderStatusLabel,
	getCourierStatusLabel,
	getEmailStatusLabel,
	getSellerStatusLabel,
	getWarehouseStatusLabel,
} from "../../../utils/statusColours";
import { styled } from "@mui/material/styles";

export const StatusBox = ({ status, statusType, sx }) => {

	if (status === "") return "";

	const StatusText = () => {
		switch (statusType) {
		case "order":
			return getOrderStatusLabel(status);
		case "email":
			return getEmailStatusLabel(status);
		case "sellers":
			return getSellerStatusLabel(status);
		case "warehouse":
			return getWarehouseStatusLabel(status);	
		default:
			return getCourierStatusLabel(status);
		}
	};
	
	const StyledBox = styled(Box)(({ theme }) => ({
		backgroundColor: theme.palette.statusbox.main,
	}));

	return (
		<StyledBox
			component="span" 
			className="status-button"
			sx={{
				border: getStatusBorder(status),
				color: getStatusColour(status),
				...sx,
			}}
		>
			{StatusText().toUpperCase()}
		</StyledBox>
	);
};