import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Box,
} from "@mui/material";
import { getKeyValue } from "../../../utils/object";

export default function ItemDisplay({ 
	item, 
	itemType, 
	isVisible,
	orderRulesRefData,
}) {
	
	if (
		!item ||
		!itemType ||
		!orderRulesRefData ||
		Object.keys(orderRulesRefData).length === 0 ||
		Object.keys(item).length === 0 
	) return null;
	
	return (
		itemType === "rule" && isVisible && (
			<Box>
				<Table sx={{ width: "50%", m: 2 }}>
					<TableHead>
						<TableRow>
							<TableCell colSpan={5}>
								<Typography
									component="h3"
									variant="h6"
									m={2}
									sx={{ fontSize: "100%", fontWeight: "bold" }}
								>
									Rule Conditions:
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align="left">Type</TableCell>
							<TableCell align="left">Comparator</TableCell>
							<TableCell align="left">Numeric Value</TableCell>
							<TableCell align="left">Text Value</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{item.conditions.map((condition, index) => (
							<TableRow key={index}>
								<TableCell align="left">
									{getKeyValue(orderRulesRefData.condition_types, "id", condition.type, "name")}
								</TableCell>
								<TableCell align="left">{condition.comparator || "-"}</TableCell>
								<TableCell align="left">{condition.numeric_value || "-"}</TableCell>
								<TableCell align="left">{condition.text_value || "-"}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
		)
	);

}
