import {
	useState,
	useContext,
	useEffect
} from "react";
import { DataContext } from "../../../App";

const useFetchData = (
	seller,
	url,
	params,
	pageRows,
) => {

	const context = useContext(DataContext);
	const [recordsTotal, setRecordsTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [isAllDataFetched, setIsAllDataFetched] = useState(false);
	const [allData, setAllData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingProgress, setLoadingProgress] = useState("");
	const [status, setStatus] = useState(true);

	useEffect(() => {

		const controller = new AbortController();
		const signal = controller.signal;
		let mounted = true;
		let total = 0;

		const pageParams = new URLSearchParams(params);
		pageParams.append("results", pageRows);
		pageParams.append("page", page);

		const getData = async () => {

			if (!seller || !url || !mounted) return;

			setIsLoading(true);

			try {

				const response = await context.dataProvider.getPaginatedTableData(seller, url, signal, pageParams);

				if (response.data.data.length) {

					if (response.data.total && response.data.total > total) {
						total = response.data.total;
					}

					if (total) {
						const pages = Math.ceil(Number(total) / Number(pageRows));
						setLoadingProgress(`Loading page ${page} of ${pages}`);
					} else {
						setLoadingProgress(`Loading page ${page}`);
					}

					setData(data => [...data, response.data.data]);
					setStatus(true);
					setPage(page => page + 1);
				}

				if (!response.data.data.length) {
					setIsAllDataFetched(true);
					setIsLoading(false);
					return;
				}

			} catch (err) {
				setLoadingProgress("Error loading data, please refresh the page or contact support");
				setIsLoading(false);
				setStatus(false);
			}

		};
		getData();

		return () => {
			mounted = false;
			controller.abort();
		};

	}, [context.dataProvider, page, pageRows, params, seller, url]);

	useEffect(() => {
		if (isAllDataFetched && data.length > 0) {
			setAllData(data);
			setIsLoading(false);

			if (!recordsTotal) {
				setRecordsTotal(Math.ceil(Number(data.flat().length)));
			}

			return;
		}
	}, [data, isAllDataFetched, recordsTotal]);

	return {
		recordsTotal,
		allData,
		isLoading,
		loadingProgress,
		status,
	};

};

export default useFetchData;