import React, {
	useState,
	useContext,
	useEffect,
} from "react";
import {
	Box,
	Grid,
	Paper,
} from "@mui/material";
import {
	getCurrentDateTruncated,
	getFormattedStartTime,
	getFormattedEndTime
} from "../../utils/date.js";
import { DataContext } from "../../App";
import { convertToPercentage } from "../../utils/integer.js";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import DataTable from "../Shared/Table/TableHideShowColumn";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function CourierPerformance() {

	const context = useContext(DataContext);
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 1);

	const [state, setState] = useState({
		startDate: initStartDate,
		endDate: initEndDate,
		courierTableData: [],
		isLoading: false,
	});

	useEffect(() => {
		const getCourierPerformanceData = async () => {

			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
				};
			});
			
			const params = new URLSearchParams({
				startTime: getFormattedStartTime(state.startDate),
				endTime: getFormattedEndTime(state.endDate),
			});

			await context.dataProvider.getCourierPerformance(params)
				.then(res => {
					const formattedData = res.data.map(item => ({
						...item,
						on_time_percentage: convertToPercentage(item.on_time_percentage),
						late_percentage: convertToPercentage(item.late_percentage),
						previous_month_on_time_percentage: convertToPercentage(item.previous_month_on_time_percentage),
						change_from_previous_month: convertToPercentage(item.change_from_previous_month),
					}));
					setState(previousState => ({
						...previousState,
						courierTableData: formattedData,
						isLoading: false,
					}));
				})
				.catch(() => {
					setState(previousState => ({
						...previousState,
						courierTableData: [],
						isLoading: false,
					}));
				});
		
		};
		getCourierPerformanceData();
	}, [context.dataProvider, state.startDate, state.endDate]);

	const tableHeaders = [
		{ key: "courier_service", label: "Courier Service", defaultColumn: true, type: "text" },
		{ key: "carrier", label: "Courier", defaultColumn: false, type: "text" },
		{ key: "sla", label: "SLA (Days)", defaultColumn: false, type: "text" },
		{ key: "despatch_week", label: "Despatch Week", defaultColumn: false, type: "text" },
		{ key: "orders", label: "Orders (Total)", defaultColumn: false, type: "text" },
		{ key: "on_time", label: "On Time", defaultColumn: false, type: "text" },
		{ key: "late", label: "Late", defaultColumn: false, type: "text" },
		{ key: "on_time_percentage", label: "On Time (%)", defaultColumn: false, type: "text" },
		{ key: "late_percentage", label: "Late (%)", defaultColumn: false, type: "text" },
		{ key: "previous_month_on_time_percentage", label: "On Time Previous Month (%)", defaultColumn: false, type: "text" },
		{ key: "change_from_previous_month", label: "Change From Previous Month (%)", defaultColumn: false, type: "text" },
	];

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue
			};
		});
	};

	const HeadingContent = () => (
		<>
			<Grid item xs />
			<Grid item xs="auto">
				<Grid item xs="auto" mt={1}>
					<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
					<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
				</Grid>
			</Grid>
		</>
	);

	return (
		<Box id="courier-performance">
			<PageHeading pageTitle={"Courier Performance"}>
				<HeadingContent/>
			</PageHeading>
			<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
				<DataTable
					name="courier_performance"
					headers={tableHeaders}
					data={state.courierTableData}
					isLoading={state.isLoading}
					isCSVDownloadVisible={true}
				/>
			</Paper>
		</Box>
	);
	
}
