import React, {
	useState,
	useContext
} from "react";
import {
	Button,
	MenuItem,
	Menu,
	ListItemText,
	ListItemIcon,
	TextField,
	Fade,
	useMediaQuery,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Box
} from "@mui/material";
import { Snackbar } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ArrowRight from "@mui/icons-material/ArrowRightSharp";
import { useTheme } from "@mui/material/styles";
import { DataContext } from "../../../App";
import { AlertBox } from "../../Shared/Alert/Alert";

export const ViewButtonMultiLinks = ({
	linkData,
	order,
	seller,
	email
}) => {

	const context = useContext(DataContext);
	const [state, setState] = useState({
		emailTypeSending: "",
		isModalOpen: false,
		modalTitle: "",
		modalMessage: "",
		warehouseModalMessage: "",
		modalMessageIfCustomsEmailType: "",
		modalPostURLResponse: "",
		isCustomsLinkFieldEmpty: false,
		customLinkFieldErrorMessage: "",
		showAlert: false,
	});

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClickButton = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseButton = () => {
		setAnchorEl(null);
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

	const handleCloseModal = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
			};
		});
	};

	const onClickButtonMenuShowModal = (name, label) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
				modalTitle: "Delivery SLA email",
				modalMessage: `Please confirm you wish to send the ${label} email to ${seller} about order number ${order}.`,
				warehouseModalMessage: `Please confirm you wish to send the ${label} email to the warehouse about order number ${order}.`,
				modalMessageIfCustomsEmailType: `Please provide the link to the customs website and confirm you wish to send the Customs email to ${seller} about order number ${order}.`,
				emailTypeSending: name,
			};
		});
	};

	const modalSendEmail = async () => {

		const params = new URLSearchParams();
		params.append("order_number", order);
		params.append("email_type", state.emailTypeSending);
		params.append("contact_email", email);

		if (state.emailTypeSending === "customs") {
			params.append("customs_link", document.querySelector("#CustomsLinkInput").value);
		}

		const file = "";
		const postBodyData = new FormData();
		postBodyData.append("File", file);

		context.dataProvider.sendEmailDeliverySLA(order, params, postBodyData)
			.then(response => {
				setState(previousState => {
					return {
						...previousState,
						modalPostURLResponse: response.data,
						isModalOpen: false,
						alertSeverity: "info",
						alertMessage: response.data,
					};
				});
			})
			.catch(error => {
				setState(previousState => {
					return {
						...previousState,
						modalPostURLResponse: error.response.data,
						isModalOpen: false,
						alertSeverity: "error",
						alertMessage: "Error encountered whilst attempting to send email",
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				showAlert: true,
			};
		});
		setAnchorEl(null);

	};

	const onClickModalSendEmail = () => {

		if (state.emailTypeSending === "customs") {

			if (document.querySelector("#CustomsLinkInput").value === "") {
				setState(previousState => {
					return {
						...previousState,
						isCustomsLinkFieldEmpty: true,
						customLinkFieldErrorMessage: "customs link required",
					};
				});
			} else {
				setState(previousState => {
					return {
						...previousState,
						isCustomsLinkFieldEmpty: false,
						customLinkFieldErrorMessage: "",
						isModalOpen: false,
					};
				});
				modalSendEmail();
			}

		} else {
			modalSendEmail();
		}

	};

	const Modal = () => {
		return (
			<Dialog
				fullScreen={fullScreen}
				open={state.isModalOpen}
				onClose={handleCloseModal}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					{state.modalTitle}
				</DialogTitle>
				<DialogContent>
					{state.emailTypeSending === "customs" ?
						<>
							<DialogContentText>
								{state.modalMessageIfCustomsEmailType}
							</DialogContentText>
							<form>
								<TextField
									id="CustomsLinkInput"
									label="Customs link"
									variant="outlined"
									type="text"
									sx={{ marginTop: 3 }}
									placeholder="eg www.customs.co.uk"
									fullWidth
									autoFocus
									required
									error={state.isCustomsLinkFieldEmpty}
									helperText={state.customLinkFieldErrorMessage}
								/>
							</form>
						</>
						: state.emailTypeSending === "latedelivery" ?
							<DialogContentText>
								{state.warehouseModalMessage}
							</DialogContentText>
							:
							<DialogContentText>
								{state.modalMessage}
							</DialogContentText>
					}
				</DialogContent>
				<DialogActions
					sx={{ paddingBottom: 2, paddingRight: 2 }}
				>
					<Button
						autoFocus
						onClick={handleCloseModal}
						variant="outlined"
						sx={{ marginRight: 2 }}
					>
            Cancel
					</Button>
					<Button
						autoFocus
						onClick={onClickModalSendEmail}
						variant="contained"
					>
            Send Email
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const ButtonMenuLinks = () => {
		if (linkData.length > 0) {
			return (
				linkData.map(({ name, label }, index) =>
					<MenuItem
						key={index}
						onClick={() => onClickButtonMenuShowModal(name, label)}
					>
						<ListItemIcon>
							<ForwardToInboxIcon />
						</ListItemIcon>
						<ListItemText>
              Send {label} email
						</ListItemText>
						<ArrowRight />
					</MenuItem>
				)
			);
		} else {
			return (
				<MenuItem>
					<ListItemText>
            No data found
					</ListItemText>
				</MenuItem>
			);
		}
	};

	const onAlertHandleClose = (reason) => {
		if (reason === "clickaway") {
			return;
		}

		setState(previousState => {
			return {
				...previousState,
				showAlert: false,
			};
		});
	};

	return (
		<>
			<Button
				variant="outlined"
				id="fade-button"
				aria-controls="fade-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClickButton}
				disableElevation
			>
				<MoreHorizIcon fontSize="small" />
			</Button>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				MenuListProps={{
					"aria-labelledby": "fade-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseButton}
				TransitionComponent={Fade}
				elevation={5}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<ButtonMenuLinks />
			</Menu>

			<Modal />

			<Snackbar open={state.showAlert} autoHideDuration={8000} onClose={onAlertHandleClose} mb={2}>
				<Box>
					<AlertBox
						onClose={onAlertHandleClose}
						severity={state.alertSeverity}
						message={state.alertMessage}
					/>
				</Box>
			</Snackbar>
		</>
	);
};
