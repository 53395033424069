import React, {
	useState,
	useEffect
} from "react";
import {
	Box,
	Button,
	InputLabel,
} from "@mui/material";
import {
	Formik,
	Form,
	Field
} from "formik";
import { TextfieldFormik } from "../../../Shared/TextField/TextField";
import * as Yup from "yup";
import { DataContext } from "../../../../App";
import ModalFull from "../../../Shared/Modal/Modal";
import { capitalizeFirstLetter } from "../../../../utils/text";

export default function FinanceDetails({
	sellerDetailsForm,
	warehouseMappingsForm,
	existingSeller,
	sellerOperation,
	onPanelsVisibleChange,
}) {

	const context = React.useContext(DataContext);
	const INITIAL_FORM_STATE = {
		invoices_sent_to_name: existingSeller?.invoices_sent_to_name || "",
		invoices_sent_to: existingSeller?.invoices_sent_to || "",
		preferred_currency: existingSeller?.preferred_currency || "GBP",
		has_vat: existingSeller?.has_vat || false,
		vat_number: existingSeller?.vat_number || "",
		eori_number: existingSeller?.eori_number || "",
	};

	const FORM_VALIDATION = Yup.object().shape({
		invoices_sent_to_name: Yup.string(),
		invoices_sent_to: Yup.string()
			.email("Invoices sent to email contact required"),
		preferred_currency: Yup.string()
			.min(3, "Currency code should be exactly 3 characters")
			.max(3, "Currency code should be exactly 3 characters")
			.required("Preferred currency required")
	});

	const [state, setState] = useState({
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
		isLoading: false,
		submitButton: "Submit",
		sellerStatus: false,
		sellerError: "",
		isFormSubmitted: false,
		isPageInEditMode: true,
	});

	const saveSeller = async (seller) => {
		try {
			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
					isFormSubmitted: true,
					sellerStatus: null,
					sellerError: "",
				};
			});

			if (sellerOperation === "edit") {
				await context.dataProvider.updateSeller(existingSeller.id, seller);
			} else {
				await context.dataProvider.createSeller(seller);
			}

			setState(previousState => {
				return {
					...previousState,
					sellerStatus: true,
				};
			});
		} catch (error) {
			if (error.response.data) {
				setState(previousState => {
					return {
						...previousState,
						sellerError:  error.response.data,
					};
				});
			}

			setState(previousState => {
				return {
					...previousState,
					sellerStatus: false,
				};
			});
		}

		setState(previousState => {
			return {
				...previousState,
				isLoading: false,
			};
		});
	};

	const handleBackButton = () => {
		onPanelsVisibleChange({
			isSellerDetailsPanelVisible: false,
			isWarehouseMappingsPanelVisible: true,
			isFinanceDetailsPanelVisible: false,
		});
	};

	const onSubmitForm = (values) => {
		values.seller_warehouse_mappings = warehouseMappingsForm;
		const seller = Object.assign(sellerDetailsForm, values);

		return saveSeller(seller);
	};

	const initialModalState = () => {
		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "",
				modalBodyMessage1: "",
				modalBodyMessage2: "",				
				modalButtonVariant1: "outlined",
				modalButtonText1: "Close",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "",
				modalButtonText2: "",
				modalButtonActionType2: "",
				modalButtonAction2: "",
				modalButtonColor2: state.modalButtonColor2,
			};
		});
	};

	useEffect(() => {
		const modalSellerSaved = () => {
			if (state.isFormSubmitted === false) return;

			initialModalState();

			if (state.sellerStatus === true) {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Seller successfully " + (sellerOperation === "edit" ? "updated": "created"),
						modalBodyMessage1: "You have successfully " + (sellerOperation === "edit" ? "updated": "created")
							+ ` seller: ${sellerDetailsForm.short_name}`,
						modalButtonText2: "Return to Seller Configuration",
						modalButtonActionType2: "link",
						modalButtonAction2: "/seller-configuration",
					};
				});
			}

			if (state.sellerStatus === false) {
				if (state.sellerError !== "") {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Seller "+ (sellerOperation === "edit" ? "update": "creation") + " failed",
							modalBodyMessage1: `${capitalizeFirstLetter(state.sellerError)}, please amend and re-submit.`,
							modalBodyMessage2: "If the problem continues please raise with engineering",
							isFormSubmitted: false,
						};
					});
				}

				if (state.sellerError === "") {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Seller " + (sellerOperation === "edit" ? "update": "creation") + " failed",
							modalBodyMessage1: "Please raise with engineering",
						};
					});
				}

			}

			setState(previousState => {
				return {
					...previousState,
					isModalOpen: true,
				};
			});

		};

		return modalSellerSaved();
	}, [state.isFormSubmitted, state.sellerStatus, state.sellerError]);

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitForm(values)}
			>
				<Form
					noValidate
					autoComplete="off"
				>

					<TextfieldFormik
						name="invoices_sent_to_name"
						label="Invoice name"
					/>

					<TextfieldFormik
						name="invoices_sent_to"
						label="Invoices sent to"
						type="email"
					/>

					<TextfieldFormik
						name="preferred_currency"
						label="Preferred currency"
						required
					/>

					<InputLabel sx={{pt:1, pb:0.5}}>
						<Field
							name="has_vat"
							type="checkbox"
							className="checkbox-field"
						/>
						Has VAT
					</InputLabel>

					<TextfieldFormik
						name="vat_number"
						label="VAT number"
					/>

					<TextfieldFormik
						name="eori_number"
						label="EORI number"
					/>

					<ModalFull
						onModalClose={onModalClose}
						isModalOpen={state.isModalOpen}
						modalBodyTitle={state.modalBodyTitle}
						modalBodyMessage1={state.modalBodyMessage1}
						modalBodyMessage1Data={state.modalBodyMessage1Data}
						modalBodyMessage2={state.modalBodyMessage2}
						modalButtonVariant1={state.modalButtonVariant1}
						modalButtonText1={state.modalButtonText1}
						modalButtonActionType1={state.modalButtonActionType1}
						modalButtonAction1={state.modalButtonAction1}
						modalButtonVariant2={state.modalButtonVariant2}
						modalButtonText2={state.modalButtonText2}
						modalButtonActionType2={state.modalButtonActionType2}
						modalButtonAction2={state.modalButtonAction2}
						modalButtonColor2={state.modalButtonColor2}
					/>

					<Box className="align-content-right">
						<Button
							variant="outlined"
							color="secondary"
							className="back-button"
							onClick={() => handleBackButton()}
						>
							Back
						</Button>
						<Button
							type="submit"
							variant="contained"
							disabled={state.isFormSubmitted}
						>
							{state.isFormSubmitted ? "Submitted" : state.submitButton}
						</Button>
					</Box>
				</Form>
			</Formik>
		</>
	);
}