import React from "react";
import {
	Box,
	Grid,
	Typography,
	Link,
	ThemeProvider,
	StyledEngineProvider,
} from "@mui/material";
import { 
	LightTheme, 
	DarkTheme 
} from "../../utils/baseTheme";
import CssBaseline from "@mui/material/CssBaseline";
import StatusBarBasic from "../Shared/StatusBar/StatusBarBasic";

export default function ErrorPage({ errorType }) {

	const isDarkMode = JSON.parse(localStorage.getItem("OP-Theme"));

	const HomepageLink = () => {
		return (
			<>
				Click <Link href="/" target="_self" rel="noreferrer">here</Link> to return to the homepage.
			</>
		);
	};
	
	const TitleHeading = () => {
		return (
			<>
				<Typography variant="h5" mb={2}>
					Error - Page not found
				</Typography>
				<Grid item xs />
				<Typography variant="body1">
					The page you are looking for does not exist. <HomepageLink/>
				</Typography>
			</>
		);
	};

	const TitleHeadingError = () => {
		return (
			<>
				<Typography variant="h5" mb={2}>
					An Error Occurred!
				</Typography>
				<Grid item xs />
				<Typography variant="body1" mb={2}>
					An error occurred while trying to load the page.
				</Typography>
				<Typography variant="body1">
					<HomepageLink/>
				</Typography>				
			</>
		);
	};

	return (
		<Box id="error-page">

			<Grid container spacing={2}>
				
				<Grid item xs={12}>
					<Box sx={{ p: 2 }} elevation={2}>

						{errorType === "boundary" ?
							<>
								<StyledEngineProvider injectFirst>
									<ThemeProvider theme={isDarkMode ? DarkTheme : LightTheme }>
										<Box sx={{ mt: 10 }}>
											<CssBaseline/>					
											<StatusBarBasic/>
											<TitleHeadingError/>
										</Box>
									</ThemeProvider>
								</StyledEngineProvider>
							</>
							:
							<TitleHeading/>
						}

					</Box>
				</Grid>

			</Grid>
		</Box>
	);
}