import React from "react";
import {
	TextField,
	MenuItem
} from "@mui/material";
import PropTypes from "prop-types";
import {
	useField,
	useFormikContext
} from "formik";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/text";
import { LoadingStatusCircle } from "../LoadingStatus/LoadingStatus";

const DropDown = ({
	data,
	onChange,
	id,
	value,
	name,
	label,
	...otherProps
}) => {
	const [dropdownField, dropdownMeta, dropdownHelpers] = useField(id);
	const { setFieldValue } = useFormikContext();

	const handleChange = e => {
		const newValue = e.target.value;
		dropdownHelpers.setValue(newValue);
		if (onChange) {
			onChange(newValue);
		} else {
			setFieldValue(name, newValue);
		}
	};

	const dropdownProps = {
		...otherProps,
		select: true,
		value: value,
		variant: "outlined",
		fullWidth: true,
		margin: "normal",
		size: "small",
		required: true,
		error: Boolean(dropdownMeta.error),
		helperText: dropdownMeta.error || "",
	};

	const dropdown = {
		...dropdownField,
		...dropdownProps,
		name: name,
		label: label,
		onChange: handleChange
	};

	return (
		data.length > 0 ?
			<TextField {...dropdown}>
				{data.map(({ key, value, name }) => (
					<MenuItem key={key} value={value}>
						{capitalizeFirstLetterOfEachWord(name)}
					</MenuItem>
				))}
			</TextField>
			:
			<LoadingStatusCircle size={25} />
	);

};

DropDown.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	data: PropTypes.array,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]).isRequired
};

export default DropDown;