import React, {
	useContext,
	useCallback,
	useEffect,
	useState,
} from "react";
import { Link as RouterLink } from "react-router-dom";
import {
	Box,
	Grid,
	Paper,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataContext } from "../../App";
import useFetchData from "../Shared/FetchData/FetchData";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import { ViewButtonSingle } from "../Shared/ViewButton/ViewButton";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Button from "@mui/material/Button";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function SellerConfiguration() {

	const context = useContext(DataContext);
	const sellers = useFetchData(useCallback(() => context.dataProvider.getSellers(), [context.dataProvider]));
	const warehouses = useFetchData(useCallback(() => context.dataProvider.getWarehouseResponse(), [context.dataProvider]));
	const [warehouseMap, setWarehouseMap] = useState(new Map());

	const page_data = {
		sellers: {
			resultsData: sellers.results.sort((a, b) => a.name > b.name ? 1 : -1),
			resultsDataTotal: sellers.results.length,
			status: sellers.status,
			isLoading: sellers.isLoading,
		},
		warehouses: {
			resultsData: warehouses.results,
			status: warehouses.status,
			isLoading: warehouses.isLoading,
		}
	};

	useEffect(() => {
		if (!page_data.warehouses.resultsData) {
			return;
		}
		const map = new Map(page_data.warehouses.resultsData.map(warehouse => [warehouse.id, warehouse]));

		setWarehouseMap(map);
	}, [page_data.warehouses.resultsData]);

	const getWarehouseName = (warehouseID) => {
		return warehouseMap.get(warehouseID)?.name;
	};

	const HeadingContent = () => {
		return (
			<>
				<Grid item xs />
				<Grid item xs="auto">
					<Button
						variant="contained"
						component={RouterLink}
						to={"/seller-create"}
						state={{ sellerOperation: "create" }}
					>
						Create a Seller<AddIcon />
					</Button>
				</Grid>
			</>
		);
	};

	const SellersTableData = () => {
		if (page_data.sellers.resultsData.length > 0) {
			return (
				page_data.sellers.resultsData.map((item, index) =>
					<TableRow key={index}>
						<TableCell>{item.code || "-"}</TableCell>
						<TableCell>{item.name || "-"}</TableCell>
						<TableCell>{item.platform_id || "-"}</TableCell>
						<TableCell>{item.id || "-"}</TableCell>
						<TableCell>{getWarehouseName(item.default_warehouse_id) || "-"}</TableCell>
						<TableCell>{item.email || "-"}</TableCell>
						<TableCell>
							<StatusBox
								status={item.status}
								statusType="sellers"
							/>
						</TableCell>
						<TableCell>
							<ViewButtonSingle
								buttonTitle={"View Seller Details"}
								buttonLink={"/seller-view"}
								buttonIcon={<PersonSearchIcon />}
								buttonProps={`/${item.id}`}
							/>
						</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="7" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}
	};

	const SellersTable = () => (
		<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
			<LoadableContent isLoading={page_data.sellers.isLoading} marginBottom={4}>
				<Typography variant="h6" sx={{ mb: 2 }}>
					Sellers
					<RecordsTotal total={page_data.sellers.resultsDataTotal} />
				</Typography>
				<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell className="table-header">Seller</TableCell>
								<TableCell className="table-header">Seller Name</TableCell>
								<TableCell className="table-header">Mintsoft ID</TableCell>
								<TableCell className="table-header">Bezos ID</TableCell>
								<TableCell className="table-header">Main Warehouse</TableCell>
								<TableCell className="table-header">Contact Email</TableCell>
								<TableCell className="table-header">Status</TableCell>
								<TableCell className="table-header"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<SellersTableData />
						</TableBody>
					</Table>
				</TableContainer>
			</LoadableContent>
		</Paper>
	);

	return (
		<Box id="seller-configuration">
			<Grid container>
				<PageHeading pageTitle={"Seller Configuration"} sx={{ mb:2 }}>
					<HeadingContent/>
				</PageHeading>
				<SellersTable />
			</Grid>
		</Box>
	);
}