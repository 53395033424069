import React, {
	useContext,
	useCallback,
} from "react";
import {
	Box,
	Grid,
	Typography,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Button,
} from "@mui/material";
import { 
	Link as RouterLink,
	useParams 
} from "react-router-dom";
import { DataContext } from "../../App";
import useFetchData from "../Shared/FetchData/FetchData";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";

export default function SellerView() {

	const { id } = useParams();
	const context = useContext(DataContext);
	const {
		results,
		isLoading
	} = useFetchData(useCallback(() => context.dataProvider.getSeller(id), [context.dataProvider, id]));

	const page_data = {	
		seller: {
			id: results.id,
			code: results.short_name,
			name: results.name,
		},
		summary: {
			contact: results.contact,
			mintsoft_id: results.mintsoft_id,
			reports_sent_to: results.reports_sent_to,
			status: results.status,
			bezos_seller_id: results.id,
			default_packaging_type_id: results.default_packaging_type_id,
		},
		finance: {
			invoices_sent_to: results.invoices_sent_to,
			invoices_sent_to_name: results.invoices_sent_to_name,
			pricing_model_id: results.pricing_model_id,
			preferred_currency: results.preferred_currency,
			has_vat: results.has_vat,
			vat_number: results.vat_number,
			eori_number: results.eori_number,
		},
		warehouse_mappings : {
			warehouse_mappings: results.seller_warehouse_mappings,
		}
	};

	const PageHeading = () => {
		return (
			<Grid item xs={12} sx={{ mb: 2 }} container spacing={2}>
				<Grid item xs="auto">
					<Typography variant="h5">
						Seller: {page_data.seller.name} {page_data.seller.code ? `(${page_data.seller.code})` : ""}
					</Typography>
				</Grid>
				<Grid item xs/>
				<Grid item xs="auto">
					<Button
						variant="outlined"
						color="primary"
						component={RouterLink}
						to="/seller-edit"
						state={{
							existingSeller: results,
							sellerOperation: "edit"
						}}
					>
						Edit Seller
					</Button>
				</Grid>
			</Grid>
		);
	};

	const Summary = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
				<Typography component="h1" variant="h5" mb={1}>
					Summary
				</Typography>

				<Grid container spacing={1}>
					
					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Contact:</Box>
							<Box component="span">{page_data.summary.contact}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold" }}>Status:</Box>
							{page_data.summary.status &&
							<Box component="span">
								<StatusBox 
									sx={{ ml: 1.5 }} 
									status={page_data.summary.status} statusType="order" 
								/>
							</Box>
							}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Mintsoft ID:</Box>
							<Box component="span">{page_data.summary.mintsoft_id}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Bezos Seller ID:</Box>
							<Box component="span">{page_data.seller.id}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Reports Sent To:</Box>
							<Box component="span">{page_data.summary.reports_sent_to}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Default Packaging Type ID:</Box>
							<Box component="span">{page_data.summary.default_packaging_type_id}</Box>
						</Typography>
					</Grid>

				</Grid>
				
			</Paper>
		);
	};

	const Finance = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
				<Typography component="h1" variant="h5" mb={1}>
					Finance Details
				</Typography>

				<Grid container spacing={1}>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Invoices Sent To:</Box>
							<Box component="span">{page_data.finance.invoices_sent_to}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Has VAT:</Box>
							<Box component="span">{page_data.finance.has_vat ? "Yes" : "No"}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Invoices Sent To Name:</Box>
							<Box component="span">{page_data.finance.invoices_sent_to_name}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>VAT Number:</Box>
							<Box component="span">{page_data.finance.vat_number}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Pricing Model ID:</Box>
							<Box component="span">{page_data.finance.pricing_model_id}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>EORI Number:</Box>
							<Box component="span">{page_data.finance.eori_number}</Box>
						</Typography>
					</Grid>


					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Preferred Currency:</Box>
							<Box component="span">{page_data.finance.preferred_currency}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
					</Grid>

				</Grid>

			</Paper>
		);
	};

	const WarehouseMappingsTableData = () => {
		if (typeof page_data.warehouse_mappings.warehouse_mappings === "object" && page_data.warehouse_mappings.warehouse_mappings.length > 0) {
			return (
				page_data.warehouse_mappings.warehouse_mappings.map((item, index) => {
					return (
						<TableRow key={index}>
							<TableCell>
								{(item.warehouse_name)}
							</TableCell>
							<TableCell>
								<StatusBox 
									status={item.warehouse_status} 
									statusType="claims"
								/>
							</TableCell>
							<TableCell>
								{item.sub_warehouse_id}
							</TableCell>
						</TableRow>
					);
				})
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="3" align="center">No items found</TableCell>
				</TableRow>
			);
		}
	};

	const WarehouseMappingsTable = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
				<Typography variant="h5" mb={2} component="div">
					Warehouse Mappings
				</Typography>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell align="left">Warehouse</TableCell>
								<TableCell align="left">Status</TableCell>
								<TableCell align="left">Sub Warehouse ID</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<WarehouseMappingsTableData />
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		);
	};

	return (
		<Box id="seller-view">
			<LoadableContent isLoading={isLoading} marginBottom={5}>
				<Grid container>
					<BreadCrumb 
						breadcrumbs={[
							{ label: "Seller Configuration", url: "/seller-configuration" },
							{ label: page_data?.seller?.code }
						]}
					/>
					<PageHeading />
					<Summary />
					<Finance />
					<WarehouseMappingsTable />
				</Grid>
			</LoadableContent>
		</Box>
	);

}