import React from "react";
import {
	Breadcrumbs,
	Grid,
	Typography,
	Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const BreadCrumb = ({ breadcrumbs }) => {

	const validBreadcrumbs = breadcrumbs?.filter(crumb => crumb.label) || [];

	if (validBreadcrumbs.length === 0) return null;

	return (
		<>
			<Grid item xs="auto" className="bread-crumb" mb={2}>
				<Breadcrumbs aria-label="breadcrumb">
					{validBreadcrumbs.map((crumb, index) => (
						index === validBreadcrumbs.length - 1 ? (
							<Typography 
								key={index} 
								color="inherit"
								sx={{ 
									padding: "4px",
									margin: 0,
								}}
							>
								{crumb.label}
							</Typography>
						) : (
							<Link 
								key={index} 
								color="inherit"
								component={RouterLink} 
								to={typeof crumb.url === "string" ? crumb.url : ""}
								onClick={typeof crumb.url === "function" ? crumb.url : undefined}
								sx={{ 
									display: "inline-block",
									padding: "4px",
									margin: 0,
									backgroundColor: "primary",
									borderRadius: "4px",
									textDecoration: "underline",
									"&:hover": {
										backgroundColor: theme => theme.palette.statusbox.main,
									}
								}}
							>
								{crumb.label}
							</Link>
						)
					))}
				</Breadcrumbs>
				<Grid item xs="auto"/>
			</Grid>
		</>
	);

};
