import React, { useState } from "react";
import {
	FormControlLabel,
	FormGroup,
	Switch,
	IconButton
} from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import LightTooltip from "../LightTooltip";
import Modal from "../Modal/Modal";

export default function ColumnVisibility({ 
	columnsVisibility, 
	onVisibilityChange, 
	headers,
}) {
	
	const [state, setState] = useState({
		isModalOpen: false,
	});

	const handleModalToggle = (props) => {
		setState(previousState => ({
			...previousState,
			isModalOpen: props,
		}));
	};

	const showAllColumns = () => {
		const newColumnsVisibility = headers.reduce((acc, header) => {
			acc[header.key] = header.type !== "component";
			return acc;
		}, {});
		onVisibilityChange(newColumnsVisibility);
	};

	const ColumnSettingsModal = () => (
		<Modal
			onModalClose={() => handleModalToggle(false)}
			isModalOpen={state.isModalOpen}
			modalBodyTitle="Column Settings"
			modalBodyMessage1="Click a column name to toggle its visibility"
			modalBodyMessage1Data={<ToggleBox />}
			modalButtonVariant1="contained"
			modalButtonText1="Show All"
			modalButtonActionType1="function"
			modalButtonAction1={showAllColumns}
		/>
	);

	const ToggleBox = () => (
		<FormGroup>
			{headers.map(header => (
				header.type !== "component" && (
					<FormControlLabel 
						key={header.key} 
						control={
							<Switch 
								checked={columnsVisibility[header.key]}
								onChange={(event) => {
									const newColumnsVisibility = {
										...columnsVisibility,
										[event.target.name]: event.target.checked,
									};
									onVisibilityChange(newColumnsVisibility);
								}}
								name={header.key}
								color="primary"
							/>
						}
						label={header.label}
						disabled={header.defaultColumn || header.type === "component"}
					/>
				)
			))}
		</FormGroup>
	);

	const ColumnSettingsButton = () => (
		<LightTooltip title="Click to toggle column visibility" disableHoverListener={false} arrow>
			<IconButton
				color="inherit"
				aria-label="column settings"
				onClick={() => handleModalToggle(true)}
				sx={{ p: 0, m: 0 }}
			>
				<ViewColumnIcon fontSize="large" sx={{ mr: 2 }} />
			</IconButton>
		</LightTooltip>
	);

	return (
		<>
			<ColumnSettingsButton />
			{state.isModalOpen && <ColumnSettingsModal />}
		</>
	);
}
