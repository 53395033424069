import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Grid,
	TextField,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Link
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { AlertBox } from "../Alert/Alert";
import { LoadingStatusBox } from "../LoadingStatus/LoadingStatus";

export default function ModalFull(props) {
	const [state, setState] = useState({
		modalBodyInputFields: props.modalBodyInputFields,
	});

	const handleClose = () => {
		props.onModalClose(false);
	};

	const onChangeModalBodyInputField = (e) => {
		e.persist();

		setState(previousState => {
			return {
				...previousState,
				modalBodyInputFields: {
					...previousState.modalBodyInputFields,
					[e.target.name]: e.target.value,
				}
			};
		});
	};

	return (
		<Dialog
			open={props.isModalOpen}
			onClose={handleClose}
			keepMounted
			aria-describedby="alert-dialog-slide-description"
			fullWidth={true}
			maxWidth={props.modalSize ?? "sm"}
		>
			<DialogTitle>{props.modalBodyTitle}</DialogTitle>
			<DialogContent>

				{props.modalIsLoading && <LoadingStatusBox message="Loading..." />}

				{props.warningMessage && <AlertBox severity="warning" message={props.warningMessage} sx={{ mb:2 }}/>}

				{props.errorMessage && <AlertBox severity="error" message={props.errorMessage} sx={{ mb:2 }}/>}

				<DialogContentText id="alert-dialog-slide-description1">
					{props.modalBodyMessage1}
				</DialogContentText>

				{props.modalBodyMessage1Data && <>
					{props.modalBodyMessage1Data}
				</>
				}

				{props.modalBodyMessage1List && <List>
					{props.modalBodyMessage1List.map((s, i) =>
						<ListItem key={i}>
							<ListItemIcon>
								<CancelIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText primary={s} />
						</ListItem>
					)
					}
				</List>
				}

				{props.modalBodyMessage2 && <DialogContentText id="alert-dialog-slide-description2" mt={2}>
					{props.modalBodyMessage2}
				</DialogContentText>
				}
				{props.modalBodyInputFields && (props.modalButtonActionType2 === "function") && (
					Object.entries(props.modalBodyInputFields).map(([key, value]) => (
						<TextField
							key={key}
							name={key}
							label={value.label}
							margin="normal"
							type={value.type}
							fullWidth
							onChange={(e) => {
								onChangeModalBodyInputField(e);
							}}
						/>
					))
				)
				}
			</DialogContent>
			<DialogActions sx={{ display: "grid", justifyContent: "center", paddingBottom: "2rem" }}>
				<Grid
					container
					justifyContent="center"
				>
					<Grid item>
						{(props.modalButtonActionType1 === "close") && <Button
							variant={props.modalButtonVariant1}
							onClick={handleClose}
						>
							{props.modalButtonText1}
						</Button>
						}
						{(props.modalButtonActionType1 === "function") && <Button
							variant={props.modalButtonVariant1}
							onClick={props.modalButtonAction1}
						>
							{props.modalButtonText1}
						</Button>
						}
						{(props.modalButtonActionType1 === "link") && <Button
							variant="contained"
							component={RouterLink}
							rel="noopener noreferrer"
							to={props.modalButtonAction1}
						>
							{props.modalButtonText1}
						</Button>
						}
						{(props.modalButtonActionType1 === "externalLink") && <Button
							variant="contained"
							component={Link}
							rel="noopener noreferrer"
							href={props.modalButtonAction1} 
							target="_blank" 
						>
							{props.modalButtonText1}
						</Button>
						}
					</Grid>

					{(props.modalButtonActionType2 === "close" && props.modalButtonText2 !== "") && <Grid item ml={2}>
						<Button
							variant={props.modalButtonVariant2}
							onClick={handleClose}
							color={props.modalButtonColor2}
						>
							{props.modalButtonText2}
						</Button>
					</Grid>
					}
					{(props.modalButtonActionType2 === "function" && props.modalButtonText2 !== "") && <Grid item ml={2}>
						<Button
							variant={props.modalButtonVariant2}
							onClick={() => {
								props.modalButtonAction2(state.modalBodyInputFields);
							}}
							color={props.modalButtonColor2}
						>
							{props.modalButtonText2}
						</Button>
					</Grid>
					}
					{(props.modalButtonActionType2 === "link" && props.modalButtonText2 !== "") && <Grid item ml={2}>
						<Button
							variant="contained"
							color={props.modalButtonColor2}
							component={RouterLink}
							rel="noopener noreferrer"
							to={props.modalButtonAction2}
						>
							{props.modalButtonText2}
						</Button>
					</Grid>
					}
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
