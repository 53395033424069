import React, {
	useState,
	useContext,
} from "react";
import {
	Formik,
	Form,
} from "formik";
import { 
	Paper,
	Button,
	Box,
	Grid,
	Typography,
} from "@mui/material";
import * as Yup from "yup";
import { TextfieldFormik } from "../Shared/TextField/TextField";
import DropDown from "../Shared/FormikDropDown/DropDown";
import AddIcon from "@mui/icons-material/Add";
import { DeleteButton } from "../Shared/Buttons/Buttons";
import { comparatorTypes } from "../../utils/comparators";
import { DataContext } from "../../App";
import Modal from "../Shared/Modal/Modal";

export default function CreateNewOrderRule ({
	orderRulesRefData,
	onClose,
	reloadTable,
}) {

	const context = useContext(DataContext);
	const [state, setState] = useState({
		isFormSubmitted: false,
		isVisible: false,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalIsLoading: false,
	});

	const INITIAL_FORM_STATE = {
		rule_name: "",
		action_type: "",
		conditions: [
			{
				text_value: "",
				condition_type: "",
				comparator_type: "",
				numeric_value: 0
			}
		]
	};

	const FORM_VALIDATION = Yup.object().shape({
		rule_name: Yup.string()
			.required("Please provide a name for your new rule"),
		action_type: Yup.number()
			.required("Please select an Action"),
		conditions: Yup.array().of(
			Yup.object().shape({
				condition_type: Yup.string()
					.required("Select a Type"),
				comparator_type: Yup.string()
					.required("Select a Comparator"),
				numeric_value: Yup.string()
					.required("Value is required")
			})
		)
	});

	const handleRemoveCondition = (index, values, setFieldValue) => {
		const filteredConditions = values.conditions.filter((_, idx) => idx !== index);
		setFieldValue("conditions", filteredConditions);
	};
    
	const handleAddCondition = (values, setFieldValue) => {
		const newCondition = {
			condition_type: "",
			comparator_type: "",
			text_value: "",
			numeric_value: 0
		};
    
		const newConditions = [...values.conditions, newCondition];
		setFieldValue("conditions", newConditions);
	};
    
	const handleResetForm = (resetForm) => {
		resetForm();
		setState(previousState => {
			return {
				...previousState,
				isFormSubmitted: false,
			};
		});
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	const onSubmitForm = (values) => {

		const createOrderRule = async () => {

			setState(previousState => {
				return {
					...previousState,
					isFormSubmitted: true,
					modalIsLoading: true,
				};
			});

			const name = values.rule_name;
			const action = values.action_type;
			const conditions = values.conditions.map(condition => ({
				type: condition.condition_type,
				text_value: condition.text_value,
				numeric_value: Number(condition.numeric_value),
				comparator: condition.comparator_type
			}));

			const response = await context.dataProvider.createOrderRule( name, action, conditions );

			if (response) {
				setState(previousState => {
					return {
						...previousState,
						isModalOpen: true,
						modalIsLoading: false,
						modalBodyTitle: "Order Rule Created",
						modalBodyMessage1: `Order Rule "${name}", has successfully been created`,
						modalBodyMessage2: "",
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
					};
				});
				reloadTable(true);
			} else {
				setState(previousState => {
					return {
						...previousState,
						isModalOpen: true,
						modalIsLoading: false,
						modalBodyTitle: "Error Order Rule Creation Failed",
						modalBodyMessage1: `There was an error trying to create the order rule "${name}", please contact engineering.`,
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
					};
				});
			}

		};
		createOrderRule();

	};
    
	const CreateOrderRuleForm = () => (
		<Paper sx={{ p: 2, mb: 2, width: "100%" }} elevation={2}>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values, { resetForm }) => onSubmitForm(values, { resetForm })}
			>
				{({ values, setFieldValue, resetForm }) => (
					<Form
						noValidate
						autoComplete="off"
					>

						<TextfieldFormik
							name="rule_name"
							label="Rule Name"
							required
						/>

						<DropDown
							id="action_type"
							name="action_type"
							label="Action Type"
							value={values.action_type}
							data={orderRulesRefData?.action_types.map((a) => ({
								key: a.id,
								value: a.id,
								name: a.name
							}))}
							sx={{ width: "100%", mt: 2, mb: 1 }}
							size="small"
							required={true} />

						<Box sx={{ border: "1px solid #ccc", pl: 2, pr: 2, pb: 2, mt: 2, mb: 3, borderRadius: "4px" }}>

							<Grid item xs={12} mt={2}>
								<Typography variant="h7" component="h3">
									Condition(s)
								</Typography>
							</Grid>

							{values.conditions.map((condition, index) => (

								<Grid container alignItems="flex-start" key={index} mb={1}>
									<Grid item xs={12} sm={3} mr={2}>
										<DropDown
											id={`conditions[${index}].condition_type`}
											name={`conditions[${index}].condition_type`}
											label="Type"
											value={condition.condition_type}
											data={orderRulesRefData?.condition_types.map((c) => ({
												key: c.id,
												value: c.id,
												name: c.name
											}))}
											sx={{ width: "100%" }}
											size="small"
											required={true}
										/>
									</Grid>
									<Grid item xs={12} sm={2} mr={2}>
										<DropDown
											id={`conditions[${index}].comparator_type`}
											name={`conditions[${index}].comparator_type`}
											label="Comparator"
											value={condition.comparator_type}
											data={comparatorTypes.map((c) => ({
												key: c.id,
												value: c.value,
												name: c.name
											}))}
											sx={{ width: "100%" }}
											size="small"
											required={true}
										/>
									</Grid>
									<Grid item xs={12} sm={3} mr={2}>
										<TextfieldFormik
											name={`conditions[${index}].text_value`}
											label="Text Value"
											required
										/>
									</Grid>
									<Grid item xs={12} sm={2} mr={2}>
										<TextfieldFormik
											name={`conditions[${index}].numeric_value`}
											label="Numeric Value"
											type="number"
											required
										/>
									</Grid>

									{values.conditions.length > 1 && (
										<Grid item xs={12} sm={1} mt={1}>
											<DeleteButton
												onClick={() => handleRemoveCondition(index, values, setFieldValue)}
												icon={true}
											/>
										</Grid>
									)}
								</Grid>
							))}

							<Button
								variant="outlined"
								onClick={() => handleAddCondition(values, setFieldValue)}
								startIcon={<AddIcon />}
								sx={{ mt:2, mb:1 }}
							>
								Add Another Condition
							</Button>

						</Box>

						<Grid container justifyContent="space-between">
							<Grid item>
								<Grid item>
									<Button
										type="submit"
										variant="contained"
										sx={{ mr:2, mb:2 }}
										disabled={state.isFormSubmitted}
									>
										{state.isFormSubmitted ? "Submitted" : "Submit"}
									</Button>
									{state.isFormSubmitted &&
										<Button variant="outlined" onClick={() => {handleResetForm(resetForm);}} sx={{ mb:2 }}>Create another Rule <AddIcon /></Button>}
								</Grid>
							</Grid>
							<Grid item>
								<Grid item>
									<Button
										variant="outlined"
										onClick={() => { onClose(); } }
									>
										Close
									</Button>
								</Grid>
							</Grid>
						</Grid>

					</Form>
				)}
			</Formik>
		</Paper>
	);

	return (
		<>
			<CreateOrderRuleForm />
			<Modal
				onModalClose={onModalClose}
				isModalOpen={state.isModalOpen}
				modalBodyTitle={state.modalBodyTitle}
				modalBodyMessage1={state.modalBodyMessage1}
				modalButtonVariant1={state.modalButtonVariant1}
				modalButtonText1={state.modalButtonText1}
				modalButtonActionType1={state.modalButtonActionType1}
				modalButtonAction1={state.modalButtonAction1}
			/>
		</>
	);
}