import React, {
	useContext,
	useState,
	useEffect,
} from "react";
import {
	Box,
	Grid,
	Paper,
	Typography,
	IconButton,
	Button,
} from "@mui/material";
import { DataContext } from "../../App";
import DataTable from "../Shared/Table/Table";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { getKeyValue } from "../../utils/object";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LightTooltip from "../Shared/LightTooltip";
import AddIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import CreateNewOrderRule from "./CreateNewOrderRule";
import { DeleteButton } from "../Shared/Buttons/Buttons";  
import Modal from "../Shared/Modal/Modal";
import { handleAxiosError } from "../../utils/error";
import MoreHorizIcon from "@mui/icons-material/ManageSearch";
import { useNavigate } from "react-router-dom";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function OrderRules() {

	const context = useContext(DataContext);
	const navigate = useNavigate();
	const [state, setState] = useState({
		isLoading: null,
		orderRules: [],
		orderRulesRefData: [],
		resultsTotal: 0,
		isVisible: false,
		reloadTable: false,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalIsLoading: false,
	});

	useEffect(() => {

		setState(prevState => ({
			...prevState,
			isLoading: true,
		}));

		const getOrderRules = async () => {
			const res = await context.dataProvider.getOrderRules();
			return res ? res.data.map(rule => ({ ...rule, isVisible: false })) : [];
		};

		const getOrderRulesRefData = async () => {
			const res = await context.dataProvider.getOrderRulesRefData();
			return res.data;
		};

		Promise.all([getOrderRules(), getOrderRulesRefData()])
			.then(([orderRules, orderRulesRefData]) => {
				setState(prevState => ({
					...prevState,
					orderRules,
					orderRulesRefData,
					resultsTotal: orderRules.length,
					isLoading: false,
				}));
			});

	}, [context.dataProvider, state.reloadTable]);

	const tableHeaders = [	
		{
			key: "name",
			label: "Name",
		},
		{
			key: "custom_component_1",
			label: "Action",
			component: (rowData) => <Action data={rowData} />,
		},
		{
			key: "custom_component_2",
			label: "Conditions",
			component: (rowData) => <ToggleConditionsViewButton 
				isVisible={rowData.isVisible} 
				onToggle={() => toggleItemVisibility(rowData)}
			/>,
		},
		{
			key: "custom_component_4",
			label: "",
			component: (rowData) => <Detail data={rowData} />,
		},
		{
			key: "custom_component_3",
			label: "",
			component: (rowData) => <Delete data={rowData} />,
		},
	];

	const handleToggleRulePanel = (forceClose = false) => {
		setState(prevState => ({
			...prevState,
			isVisible: forceClose ? false : !prevState.isVisible,
		}));
	};

	const HeadingContent = () => (
		<>
			<Grid item xs />
			<Grid item xs="auto">
				<Button 
					title="Create New Rule"
					variant="contained"
					onClick={() => handleToggleRulePanel()}
				>
					Create New Rule {state.isVisible ? <MinusIcon/> : <AddIcon/>}
				</Button>
			</Grid>
		</>
	);

	const Rules = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Grid item xs={12}>
				<Typography component="h2" variant="h5">
					Rules
					<RecordsTotal total={state.resultsTotal} />
				</Typography>
				<Grid item xs />
			</Grid>
			<DataTable
				data={state.orderRules.sort((a, b) => a.id > b.id ? -1 : 1)}
				headers={tableHeaders}
				dateHeaders={[]}
				isLoading={state.isLoading}
				usePagination={false}
				itemType="rule"
				orderRulesRefData={state.orderRulesRefData}
			/>
		</Paper>
	);

	const toggleItemVisibility = (data) => {
		const id = data.data.id;
		setState(prevState => ({
			...prevState,
			orderRules: prevState.orderRules.map(rule =>
				rule.id === id ? { ...rule, isVisible: !rule.isVisible } : rule
			),
		}));
	};

	const ToggleConditionsViewButton = ({ onToggle }) => (
		<LightTooltip
			title="Click to view Rule Condition(s)"
			arrow
		>
			<IconButton 
				aria-label="Click to view Rule Condition(s)"
				onClick={onToggle}
			>
				<VisibilityIcon />
			</IconButton>
		</LightTooltip>
	);

	const Action = (data) => {
		const actionID = data.data.data.action;
		const action = getKeyValue(state.orderRulesRefData.action_types, "id", actionID, "name");
		return (action === "" || action == null) ? "not found" : action;
	};

	const reloadTable = (boolean) => {
		if (boolean) {
			setState(prevState => ({
				...prevState,
				reloadTable: true,
				isVisible: false,
			}));
		}
	};

	const handleDeleteRule = (id, name) => {

		if (id === "" || name === "") return;

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
				modalIsLoading: true,
			};
		});

		context.dataProvider.deleteOrderRule(id)
			.then(() => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Order Rule Deleted",
						modalBodyMessage1: `Order Rule "${name}" has been deleted.`,
						modalBodyMessage2: "",
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
						reloadTable: !state.reloadTable,
					};
				});
			})
			.catch((error) => {
				setState(previousState => {
					return {
						...previousState,
						modalBodyTitle: "Error Deleting Order Rule",
						modalBodyMessage1: `There was an error trying to delete the Order Rule "${name}".`,
						modalBodyMessage2: `Error: ${handleAxiosError(error)}, please contact engineering.`,
						modalButtonVariant1: "contained",
						modalButtonText1: "Close",
						modalButtonActionType1: "close",
						modalButtonAction1: "",
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				modalButtonVariant2: "",
				modalButtonText2: "",
				modalButtonActionType2: "",				
				isModalOpen: true,
				modalIsLoading: false,
			};
		});

	};

	const handleConfirmDeleteRule = (id, name) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
				modalIsLoading: false,
				modalBodyTitle: "Delete Order Rule?",
				modalBodyMessage1: `Please confirm you want to delete Order Rule "${name}"?`,
				modalBodyMessage2: "",
				modalButtonVariant1: "contained",
				modalButtonText1: "Cancel",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalButtonVariant2: "contained",
				modalButtonText2: "Delete",
				modalButtonActionType2: "function",
				modalButtonAction2: () => handleDeleteRule(id, name),
				modalButtonColor2: "destructive",
			};
		});
	};

	const Delete = (data) => {
		const id = data.data.data.id;
		const name = data.data.data.name;
		return (
			<DeleteButton
				onClick={() => handleConfirmDeleteRule(id, name)}
				icon={true}
			/>
		);
	};

	const Detail = (data) => {

		const getRuleDetails = () => {
			navigate("/rule-detail", { 
				state: { 
					rule: data.data.data,
					orderRulesRefData: state.orderRulesRefData
				}
			});
		};
		
		return (
			<Button 
				title="Click for more details"
				variant="outlined"
				onClick={() => getRuleDetails()}
			>
				<MoreHorizIcon fontSize="small" />
			</Button>
		);

	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	return (
		<Box id="order-rules">
			<LoadableContent marginBottom={4} isLoading={state.isLoading}>
				<Grid container>
					<Grid item xs={12}>
						<PageHeading pageTitle={"Order Rules"} sx={{ mb:2 }}>
							<HeadingContent/>
						</PageHeading>

						{!state.isLoading && 
						state.orderRulesRefData?.action_types?.length > 0 &&
						state.orderRulesRefData?.condition_types?.length > 0 &&
						state.isVisible &&
							<CreateNewOrderRule 
								orderRulesRefData={state.orderRulesRefData}
								onClose={() => handleToggleRulePanel(true)}
								reloadTable={reloadTable}
							/>
						}

					</Grid>
					<Grid item xs={12} mb={4}>
						<Rules />
					</Grid>
					<Modal
						onModalClose={onModalClose}
						isModalOpen={state.isModalOpen}
						modalBodyTitle={state.modalBodyTitle}
						modalBodyMessage1={state.modalBodyMessage1}
						modalButtonVariant1={state.modalButtonVariant1}
						modalButtonText1={state.modalButtonText1}
						modalButtonActionType1={state.modalButtonActionType1}
						modalButtonAction1={state.modalButtonAction1}
						modalButtonVariant2={state.modalButtonVariant2}
						modalButtonText2={state.modalButtonText2}
						modalButtonActionType2={state.modalButtonActionType2}
						modalButtonAction2={state.modalButtonAction2}
						modalButtonColor2={state.modalButtonColor2}
					/>
				</Grid>
			</LoadableContent>
		</Box>
	);

}
