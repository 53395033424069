export const getStatusColour = (status) => {
	switch (status.toLowerCase()) {
	case "despatched":
		return "seagreen";
	case "returned":
		return "seagreen";
	case "picked":
		return "cornflowerblue";
	case "processed":
		return "cornflowerblue";
	case "processing":
		return "cornflowerblue";
	case "inforeceived":
		return "slateblue";
	case "invoiced":
		return "slateblue";
	case "intransit":
		return "cornflowerblue";
	case "outfordelivery":
		return "cornflowerblue";
	case "delivered":
		return "seagreen";
	case "attemptfail":
		return "rgb(161, 51, 51)";
	case "exception":
		return "rgb(161, 51, 51)";
	case "cancelled":
		return "lightgrey";
	case "pending":
		return "lightgrey";
	case "expired":
		return "lightgrey";
	case "awaitingdelivery":
		return "rgb(161, 51, 51)";
	case "bookedin":
		return "seagreen";
	case "new":
		return "cornflowerblue";
	case "onbackorder":
		return "orange";
	case "fraud risk":
		return "rgb(255,87,51)"; // orange
	case "awaitingconfirmation":
		return "rgb(161, 51, 51)";
	case "awaiting confirmation":
		return "rgb(161, 51, 51)";
	case "awaiting picking":
		return "rgb(255,215,0)"; // yellow
	case "error processing report":
		return "rgb(161, 51, 51)";
	case "ready to download":
		return "rgb(60,179,113)";
	case "notscanned":
		return "#d4af37";
	case "failed":
		return "rgb(161, 51, 51)";
	case "printed":
		return "rgb(60,179,113)";
	case "on back order":
		return "orange";
	case "invoice failed":
		return "rgb(161, 51, 51)";
	case "picking started":
		return "rgb(65,105,225)";
	case "query raised":
		return "rgb(161, 51, 51)";
	case "contacted":
		return "rgb(60,179,113)";
	case "not contacted":
		return "rgb(161, 51, 51)";
	case "yes":
		return "rgb(60,179,113)"; // green
	case "no":
		return "rgb(161, 51, 51)"; // red                                  
	case "partially contacted":
		return "rgb(255,215,0)"; // yellow
	case "deliveryattempted":
		return "pink";
	case "pack and hold":
		return "rgb(192,51,255)";
	case "deleted in warehouse - despatched":
		return "rgb(161, 51, 51)"; // red
	case "live":
		return "green";
	case "frozen":
		return "red";
	case "onboarding":
		return "orange";
	case "dormant":
		return "grey";
	case "default":
		return "green";
	case "additional":
		return "orange";
	case "nomapping":
		return "grey";
	case "true":
		return "green";		
	case "false":
		return "red";	
	default:
		return "grey";
	}
};

export const getOrderStatusLabel = (status) => {
	switch (status.toLowerCase()) {
	case "new":
		return "New";
	case "on back order":
		return "On back order";
	case "fraudrisk":
		return "Fraud risk";
	case "awaitingconfirmation":
		return "Awaiting confirmation";
	case "awaiting confirmation":
		return "Awaiting confirmation";
	case "despatched":
		return "Despatched";
	case "processed":
		return "Processed";
	case "processing":
		return "Processing";
	case "returned":
		return "Returned";
	case "delivered":
		return "Delivered";
	case "cancelled":
		return "Cancelled";
	case "pending":
		return "Pending";
	case "availableforpickup":
		return "Available for pickup";
	case "picked":
		return "Picked";
	case "failed":
		return "Failed";
	case "printed":
		return "Printed";
	case "invoiced":
		return "Invoiced";
	case "invoice failed":
		return "Invoice Failed";
	case "picking started":
		return "Picking Started";
	case "query raised":
		return "Query Raised";
	default:
		return status.toLowerCase();
	}
};

export const getCourierStatusLabel = (status) => {
	switch (status.toLowerCase()) {
	case "inforeceived":
		return "Info received";
	case "intransit":
		return "In transit";
	case "outfordelivery":
		return "Out for delivery";
	case "delivered":
		return "Delivered";
	case "attemptfail":
		return "Failed attempt";
	case "exception":
		return "Exception";
	case "pending":
		return "Pending";
	case "expired":
		return "Expired";
	case "availableforpickup":
		return "Available for pickup";
	case "notscanned":
		return "Not scanned";
	case "deliveryattempted":
		return "Delivery Attempted";
	default:
		return status.toLowerCase();
	}
};

export const getSellerStatusLabel = (status) => {
	return status.toLowerCase();
};

export const getWarehouseStatusLabel = (status) => {
	switch (status.toLowerCase()) {
		case "true":
			return "LIVE";
		case "false":
			return "OFFLINE";			
		default:
			return status.toLowerCase();
	}
};

export const getReportStatusLabel = (status) => {
	switch (status.toLowerCase()) {
	case "processing":
		return "Processing";
	case "ready to download":
		return "Ready to download";
	case "error processing report":
		return "Error processing report";
	default:
		return status.toLowerCase();
	}
};

export const getEmailStatusLabel = (status) => {
	switch (status.toLowerCase()) {
	case "contacted":
		return "Contacted";
	case "not contacted":
		return "Not Contacted";
	case "yes":
		return "Yes";
	case "no":
		return "No";
	case "partially contacted":
		return "Partially Contacted";
	default:
		return status.toLowerCase();
	}
};


export const getStatusBorder = (status) => {
	if (status !== "") {
		return "1px solid " + getStatusColour(status);
	}

	return "";
};

