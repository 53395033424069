export const getEmailStatus = (emailed, count) => {

	switch (true) {
	case (emailed === 0 && count > 0):
		return "contacted";
	case (emailed > 0 && count > emailed):
		return "partially contacted";
	case (emailed !== 0 && count !== 0) && (emailed === count):
		return "not contacted";
	default:
		return "Unknown Status";
	}

};