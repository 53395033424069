import React from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import LightTooltip from "../LightTooltip";

export const ThemeToggle = ({ change, check }) => {

	return (
		<Box>
			<LightTooltip
				title="Click to toggle theme"
				arrow
			>
				<IconButton sx={{ mr: 1 }} onClick={change} color="inherit">
					{check ? <Brightness7Icon /> : <Brightness4Icon sx={{ color: "#FFF" }} />}
				</IconButton>
			</LightTooltip>
		</Box>
	);
};