import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import ErrorBoundary from "./utils/errorBoundary";
import { Authenticator } from "@aws-amplify/ui-react";
import { 
	Amplify, 
	Auth 
} from "aws-amplify";

// Configure Amplify
Amplify.configure({
	Auth: {
		region: "eu-west-1",
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID
	}
});
Auth.configure();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<StrictMode>
		<ErrorBoundary>
			<Authenticator.Provider>
				<App />
			</Authenticator.Provider>
		</ErrorBoundary>
	</StrictMode>
);
