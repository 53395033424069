import React from "react";
import {
	FormControl,
	InputLabel,
	Select
} from "@mui/material";

export default function SellerDropDown({
	label,
	id,
	onChangeSelect,
	selected,
	sx,
	size,
	map
}) {

	return (
		<>
			<FormControl size={size} sx={sx}>
				<InputLabel id={id}>{label}</InputLabel>
				<Select
					labelId={id}
					defaultValue=""
					value={selected !== 0 ? selected : ""}
					onChange={(e) => { onChangeSelect(e.target.value); }}
					autoWidth
					label={label}
				>
					{map}
				</Select>
			</FormControl>
		</>
	);
}

