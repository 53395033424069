import React, {
	useContext,
	useCallback
} from "react";
import useFetchData from "../FetchData/FetchData";
import DropDown from "../DropDown/DropDown";
import { MenuItem } from "@mui/material";
import { DataContext } from "../../../App";

export default function SellerDropDown({ onChangeSelect, selected }) {

	const context = useContext(DataContext);

	const apiData = useFetchData(useCallback(() =>
		context.dataProvider.getSellers(),
	[context.dataProvider]));

	const page_data = {
		sellers: {
			resultsData: apiData.results.sort((b, a) => a.code > b.code ? -1 : 1),
			status: apiData.status,
			isLoading: apiData.isLoading,
		}
	};

	function onSellerSelect(seller) {
		onChangeSelect(seller);
	}

	const map = page_data.sellers.resultsData.map(({ code, id, name }) => (
		<MenuItem
			key={id}
			value={`${id}-${code}-${name}`}
		>
			{code}-{id}
		</MenuItem>
	));

	return (
		page_data.sellers.resultsData &&
		<DropDown
			label={"Sellers"}
			id={"select_seller_list"}
			list={page_data.sellers.resultsData}
			onChangeSelect={onSellerSelect}
			selected={selected}
			sx={{ minWidth: 120, marginRight: "1rem", marginBottom: "1rem", backgroundColor: theme => theme.palette.statusbox.main }}
			size={"small"}
			map={map}
		/>
	);
}
