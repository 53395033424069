import React from "react";
import { styled } from "@mui/material/styles";
import {
	Toolbar,
	Button,
	Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import "./StatusBar.scss";
import { ThemeToggle } from "../StatusBar/ThemeToggle";
import { Logo } from "./logo";

export default function StatusBar({
	onHandleDrawer,
	isDrawerVisible,
	drawerWidth,
	check,
	change,
	onSignOut
}) {

	const handleSignOut = () => {
		onSignOut();
	};

	const handleDrawerOpen = () => {
		onHandleDrawer(true);
	};

	const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== "open",
	})(({ theme, open }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}));

	return (
		<Box className="AppBar">

			<AppBar 
				position="fixed" 
				open={isDrawerVisible}
				color="appbar"
				enableColorOnDark
			>

				<Toolbar>

					<IconButton
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						color="default"
						sx={{
							color: "#FFF",
							marginRight: 2,
							...(isDrawerVisible && { display: "none" }),
						}}
						size="large">
						<MenuIcon />
					</IconButton>

					<Box sx={{ width: "100%" }}>
						<Logo />
					</Box>

					<ThemeToggle
						check={check} 
						change={change} 
					/>

					<Box>
						<Button
							color="default"
							variant="outlined"
							onClick={handleSignOut}
							sx={{ whiteSpace: "nowrap", backgroundColor: "transparent" }}
							title="Sign Out"
						>
							<LogoutIcon className="display-min-width" fontSize="medium" />
							<Typography className="display-max-width">Sign Out</Typography>
						</Button>
					</Box>

				</Toolbar>
			</AppBar>

		</Box>
	);
}
