import React, {
	useState,
	useEffect,
	useContext,
} from "react";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Checkbox,
	Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { setDocumentTitle } from "../../utils/page";
import DataTable from "../Shared/Table/Table";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { DataContext } from "../../App";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import SearchFilter from "../Shared/SearchFilter/SearchFilter";
import { extractIDs } from "../../utils/object";
import { areArraysEqual } from "../../utils/array";
import { handleAxiosError } from "../../utils/error";
import Modal from "../Shared/Modal/Modal";
import { getKeyValue } from "../../utils/object";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";

export default function RuleDetail() {

	const context = useContext(DataContext);
	const { state: locationState } = useLocation();
	const rule = locationState.rule;
	const orderRulesRefData = locationState.orderRulesRefData;
	const pageName = `Rule Details: ${rule.name}`;
	setDocumentTitle(pageName);

	const [state, setState] = useState({
		allSellers: null,
		filteredSellers: null,
		filter: "",
		isLoading: false,
		isDisabled: true,
		isEditing: false,
		reloadTable: false,
		initiallySelectedCheckBoxes: [],
		selectedCheckBoxes: [],
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalIsLoading: false,
	});
	
	useEffect(() => {
		const getSellersAndMappings = () => {

			setState(prevState => ({
				...prevState,
				isLoading: true,
			}));
	
			const mapSellersWithRule = (sellerList, mapList) => {
				const mappingIds = new Set(mapList.map((m) => m.id));
				return sellerList.map((item) => ({
					...item,
					rule_enabled: mappingIds.has(item.id),
				}));
			};
	
			const getSellers = () => context.dataProvider.getSellers()
				.then(res => res.data);
	
			const params = new URLSearchParams({ rule_id: rule.id });
			const getOrderRuleToSellerMappings = () => context.dataProvider.getOrderRuleToSellerMappings(params)
				.then(res => res.data);
	
			Promise.all([getSellers(), getOrderRuleToSellerMappings()])
				.then(([sellers, orderRuleToSellerMappings]) => {
					const mappedSellers = mapSellersWithRule(sellers, orderRuleToSellerMappings);
					setState(prevState => ({
						...prevState,
						allSellers: mappedSellers,
						filteredSellers: mappedSellers,
						initiallySelectedCheckBoxes: extractIDs(orderRuleToSellerMappings),
						selectedCheckBoxes: extractIDs(orderRuleToSellerMappings),
						isLoading: false,
					}));
				})
				.catch(() => {
					setState(prevState => ({
						...prevState,
						allSellers: null,
						filteredSellers: null,
						initiallySelectedCheckBoxes: [],
						selectedCheckBoxes: [],
						isLoading: false,
					}));
				});
		};
	
		getSellersAndMappings();
	}, [context.dataProvider, state.reloadTable]);

	useEffect(() => {
		const search = () => {

			const input = state.filter.trim();
			if (input === "") {
				setState(previousState => ({
					...previousState,
					filteredSellers: state.allSellers,
					isLoading: false
				}));
				return;
			}

			const inputWords = input.replace(/[^a-zA-Z 0-9_-]/g, "")
				.toLowerCase().split(" ").filter(Boolean);

			const filteredSellers = state.allSellers.filter(item => {
				return inputWords.every(word =>
					item.code.toLowerCase().includes(word) ||
					item.name.toLowerCase().includes(word)
				);
			});

			setState(previousState => ({
				...previousState,
				filteredSellers: filteredSellers,
				isLoading: false
			}));
		};
		search();
	}, [state.filter]);

	const tableHeadersRules = [	
		{
			key: "type",
			label: "Type",
			component: (rowData) => <Type data={rowData} />,
		},
		{
			key: "comparator",
			label: "Comparator",
		},
		{
			key: "numeric_value",
			label: "Numeric Value",
		},
		{
			key: "text_value",
			label: "Text Value",
		},
	];

	const tableHeadersSellers = [
		{
			key: "id",
			label: "ID",
		},		
		{
			key: "code",
			label: "Code",
		},
		{
			key: "name",
			label: "Full Name",
		},
		{
			key: "status",
			label: "Status",
		},
		{
			key: "custom_component_1",
			label: "Rule Enabled",
			component: (rowData) => <CheckBox data={rowData} />,
		},
	];

	const Type = (data) => {
		const typeID = data.data.data.type;
		const type = getKeyValue(orderRulesRefData.condition_types, "id", typeID, "name");
		return (type === "" || type == null) ? "not found" : type;
	};

	const handleFilterChange = (value) => {
		setState(previousState => {
			return {
				...previousState,
				filter: value,
			};
		});
	};

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	const PageHeading = () => (
		<Grid item xs={12} mb={1}>
			<Typography component="h1" variant="h5">
				{pageName}
			</Typography>
		</Grid>
	);

	const Rules = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Grid item xs={12}>
				<Typography component="h2" variant="h5" mb={1}>
					Rule Conditions
					<RecordsTotal total={rule.conditions.length} />
				</Typography>
				<Grid item xs />
			</Grid>
			<DataTable
				data={rule.conditions.sort((a, b) => a.id > b.id ? -1 : 1)}
				headers={tableHeadersRules}
				dateHeaders={[]}
				isLoading={false}
				usePagination={false}
				itemType="rule"
				orderRulesRefData={orderRulesRefData}
			/>
		</Paper>
	);

	const onChangeCheckBox = (e) => {

		const sellerID = Number(e.target.name);
		const isChecked = e.target.checked;
		const isInArray = state.selectedCheckBoxes.includes(sellerID);

		if (isChecked === true && isInArray === false) {
			setState(previousState => {
				return {
					...previousState,
					selectedCheckBoxes: [...state.selectedCheckBoxes, sellerID],
				};
			});
		}

		if (isChecked === false && isInArray === true) {
			const filteredSellers = state.selectedCheckBoxes.filter(sellers => {
				return sellers !== sellerID;
			});

			setState(previousState => {
				return {
					...previousState,
					selectedCheckBoxes: filteredSellers,
				};
			});
		}

	};

	const CheckBox = (item) => {
		const name = item.data.data.id;
		return (
			<Checkbox
				checked={state.selectedCheckBoxes.includes(name) ? true : false}
				onChange={(e) => onChangeCheckBox(e)}
				inputProps={{ "aria-label": "controlled" }}
				name={name.toString()}
				disabled={state.isDisabled}
			/>
		);
	};

	const EditButtonGroup = () => {

		const handleUpdate = () => {
	
			setState(previousState => {
				return {
					...previousState,
					isModalOpen: true,
					modalIsLoading: true,
				};
			});
	
			context.dataProvider.updateOrderRuleToSellerMappings(rule.id, state.selectedCheckBoxes)
				.then(() => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Order Rules Updated",
							modalBodyMessage1: "Order Rules have successfully been updated.",
							modalBodyMessage2: "",
							modalButtonVariant1: "contained",
							modalButtonText1: "Close",
							modalButtonActionType1: "close",
							modalButtonAction1: "",
							reloadTable: !state.reloadTable,
							isEditing: false,
							isDisabled: true,
						};
					});
				})
				.catch((error) => {
					setState(previousState => {
						return {
							...previousState,
							modalBodyTitle: "Error Updating Order Rules",
							modalBodyMessage1: "There was an error trying to update the order rules.",
							modalBodyMessage2: `Error: ${handleAxiosError(error)}, please contact engineering.`,
							modalButtonVariant1: "contained",
							modalButtonText1: "Close",
							modalButtonActionType1: "close",
							modalButtonAction1: "",
							isEditing: true,
							isDisabled: false,
						};
					});
				});
	
			setState(previousState => {
				return {
					...previousState,
					modalButtonVariant2: "",
					modalButtonText2: "",
					modalButtonActionType2: "",				
					modalIsLoading: false,
				};
			});
	
		};

		const handleConfirmUpdate = () => {
			setState(previousState => {
				return {
					...previousState,
					isModalOpen: true,
					modalIsLoading: false,
					modalBodyTitle: "Update Order Rules?",
					modalBodyMessage1: "Please confirm you want to update the order rules?",
					modalBodyMessage2: "",
					modalButtonVariant1: "contained",
					modalButtonText1: "Update",
					modalButtonActionType1: "function",
					modalButtonAction1: () => handleUpdate(),
					modalButtonVariant2: "contained",
					modalButtonText2: "Cancel",
					modalButtonActionType2: "close",
					modalButtonAction2: "",
					modalButtonColor2: "destructive",
				};
			});
		};

		const handleEdit = () => {
			setState(previousState => {
				return {
					...previousState,
					isEditing: !state.isEditing,
					isDisabled: !state.isDisabled,
				};
			});
		};

		const handleReset = () => {
			setState(previousState => {
				return {
					...previousState,
					selectedCheckBoxes: state.initiallySelectedCheckBoxes,
				};
			});
		};
		
		const handleCancel = () => {
			setState(previousState => {
				return {
					...previousState,
					isEditing: false,
					isDisabled: true,
					selectedCheckBoxes: state.initiallySelectedCheckBoxes,
				};
			});
		};

		return (
			<>
				{!state.isEditing &&
					<Grid item xs={12} sm="auto">
						<Button
							title="Edit"
							variant="outlined"
							onClick={() => handleEdit()}
						>
							Edit Seller
						</Button>
					</Grid>
				}
				{state.isEditing &&
					<>
						<Grid item xs />
						<Grid item xs={12} sm="auto" mb={2} mr={2}>
							<Button
								title="Update"
								variant="contained"
								color="primary"
								onClick={() => handleConfirmUpdate()}
								disabled={areArraysEqual(state.initiallySelectedCheckBoxes, state.selectedCheckBoxes)}
							>
								Update
							</Button>
						</Grid>
						<Grid item xs={12} sm="auto" mb={2} mr={2}>
							<Button
								title="Reset"
								variant="outlined"
								color="secondary"
								onClick={() => handleReset()}
								disabled={areArraysEqual(state.initiallySelectedCheckBoxes, state.selectedCheckBoxes)}
							>
								Reset
							</Button>
						</Grid>
						<Grid item xs={12} sm="auto">
							<Button
								title="Cancel"
								variant="contained"
								color="destructive"
								onClick={() => handleCancel()}
							>
								Cancel
							</Button>
						</Grid>
					</>
				}
			</>
		);

	};

	const Sellers = () => (
		state.filteredSellers &&
		<Grid item xs={12} mt={2}>
			<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
				<Grid item xs={12}>
					<Typography component="h2" variant="h5">
						Sellers
						<RecordsTotal total={state.filteredSellers.length} />
					</Typography>
					<Grid container mt={2} mb={2}>
						<Grid item xs={12} sm="auto">
							<SearchFilter
								filter={state.filter} 
								onFilterChange={handleFilterChange}
								label={"Seller"}
							/>
						</Grid>
						<Grid item xs />
						<EditButtonGroup />
					</Grid>    
				</Grid>
				<DataTable
					data = {state.filteredSellers.sort((a, b) => {
						if (a.rule_enabled && !b.rule_enabled) return -1;
						if (!a.rule_enabled && b.rule_enabled) return 1;
						return a.code > b.code ? 1 : -1;
					})}
					headers={tableHeadersSellers}
					dateHeaders={[]}
					isLoading={false}
					usePagination={false}
				/>
			</Paper>
		</Grid>
	);

	return (
		<Box id="rule-detail">
			<LoadableContent marginBottom={4} isLoading={state.isLoading}>
				<Grid container>
					<BreadCrumb 
						breadcrumbs={[
							{ label: "Order Rules", url: "/order-rules" },
							{ label: rule?.name }
						]}
					/>
					<PageHeading/>
					<Rules/>
					<Sellers/>
					<Modal
						onModalClose={onModalClose}
						isModalOpen={state.isModalOpen}
						modalBodyTitle={state.modalBodyTitle}
						modalBodyMessage1={state.modalBodyMessage1}
						modalButtonVariant1={state.modalButtonVariant1}
						modalButtonText1={state.modalButtonText1}
						modalButtonActionType1={state.modalButtonActionType1}
						modalButtonAction1={state.modalButtonAction1}
						modalButtonVariant2={state.modalButtonVariant2}
						modalButtonText2={state.modalButtonText2}
						modalButtonActionType2={state.modalButtonActionType2}
						modalButtonAction2={state.modalButtonAction2}
						modalButtonColor2={state.modalButtonColor2}
					/>
				</Grid>
			</LoadableContent>
		</Box>
	);

}
