import React, {
	useState,
	useContext,
	useCallback,
	useMemo
} from "react";
import "./NotificationChecker.scss";
import useFetchData from "../Shared/FetchData/FetchData";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import {
	getDisplayDate,
	getCurrentDateTruncated,
	getFormattedStartTime,
	getFormattedEndTime
} from "../../utils/date.js";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import {
	Box,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { EmailTable } from "./components/EmailTable";
import { DataContext } from "../../App";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function NotificationChecker() {

	const context = useContext(DataContext);
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 1);

	const [state, setState] = useState({
		startDate: initStartDate,
		endDate: initEndDate
	});

	const params = useMemo(() => new URLSearchParams({
		startTime: getFormattedStartTime(state.startDate),
		endTime: getFormattedEndTime(state.endDate),
	}), [state.startDate, state.endDate]);

	const apiData = useFetchData(useCallback(() =>
		context.dataProvider.getOnBackOrder(params),
	[context.dataProvider, params]));

	const onBackOrderStatsResponse = useFetchData(useCallback(() =>
		context.dataProvider.getOnBackOrderStats(params),
	[context.dataProvider, params]));

	const fraudRiskStatsResponse = useFetchData(useCallback(() =>
		context.dataProvider.getOrdersFraudRisk(params),
	[context.dataProvider, params]));

	const page_data = {
		onBackOrder: {
			resultsData: apiData.results.sort((a, b) => a.bezos_order_date > b.bezos_order_date ? -1 : 1),
			resultsDataTotal: apiData.results.length,
			status: apiData.status,
			isLoading: apiData.isLoading,
		},
		stats: {
			resultsData: onBackOrderStatsResponse.results.sort((a, b) => a.seller_platform_id > b.seller_platform_id ? -1 : 1),
			resultsDataTotal: onBackOrderStatsResponse.results.length,
			status: onBackOrderStatsResponse.status,
			isLoading: onBackOrderStatsResponse.isLoading,
		},
		fraud_risk_stats: {
			resultsData: fraudRiskStatsResponse.results.sort((a, b) => a.seller_code > b.seller_code ? -1 : 1),
			resultsDataTotal: fraudRiskStatsResponse.results.length,
			status: fraudRiskStatsResponse.status,
			isLoading: fraudRiskStatsResponse.isLoading,
		},
	};

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue
			};
		});
	};

	const HeadingContent = () => (
		<>
			<Grid item xs />
			<Grid item xs="auto">
				<Grid item xs="auto" mt={1}>
					<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
					<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
				</Grid>
			</Grid>
		</>
	);

	const TableData = () => {
		if (page_data.onBackOrder.resultsData.length > 0) {
			return (
				page_data.onBackOrder.resultsData.map((item, index) =>
					<TableRow key={index}>
						<TableCell>{item.order_number}</TableCell>
						<TableCell>{item.bezos_order_date !== "" ? getDisplayDate(item.bezos_order_date) : ""}</TableCell>
						<TableCell><StatusBox status={item.bezos_status} statusType="order" /></TableCell>
						<TableCell>{item.seller_name}</TableCell>
						<TableCell>{item.seller_contact_email}</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="5" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}
	};
	const TableHeader = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<LoadableContent isLoading={page_data.onBackOrder.isLoading}>
				<Typography variant="h6" sx={{ mb: 2 }}>
					Orders On Back Order <RecordsTotal total={page_data.onBackOrder.resultsDataTotal} />
				</Typography>
				<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell className="table-header">Bezos order number</TableCell>
								<TableCell className="table-header">Bezos order date</TableCell>
								<TableCell className="table-header">Bezos status</TableCell>
								<TableCell className="table-header">Seller</TableCell>
								<TableCell className="table-header">Seller contact email</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableData />
						</TableBody>
					</Table>
				</TableContainer>
			</LoadableContent>
		</Paper>
	);

	return (
		<Box id="notification-checker">
			<PageHeading pageTitle={"Notification Checker"}>
				<HeadingContent/>
			</PageHeading>
			<TableHeader />
			<EmailTable
				type={"onbackorder"}
				tableTitle="Orders On Back Order By Seller"
				tableData={page_data.stats.resultsData}
				startDate={state.startDate}
				endDate={state.endDate}
			/>
			<EmailTable
				type={"fraudrisk"}
				tableTitle="Fraud Risk Orders By Seller"
				tableData={page_data.fraud_risk_stats.resultsData}
				startDate={state.startDate}
				endDate={state.endDate}

			/>
		</Box>
	);
}
