export const excludeById = (originalArray, idToExclude) => {
	const filteredArray = originalArray.filter(item => item.id !== idToExclude);

	return filteredArray;
};

export const areArraysEqual = (arr1, arr2) => {

	if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
	if (arr1.length !== arr2.length) return false;

	const sortedArr1 = [...arr1].sort();
	const sortedArr2 = [...arr2].sort();

	for (let i = 0; i < sortedArr1.length; i++) {
		if (sortedArr1[i] !== sortedArr2[i]) {
			return false;
		}
	}

	return true;
};
