import React, {
	useState,
	useMemo,
	useRef,
	createContext
} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./scss/main.scss";
import { Authenticator } from "@aws-amplify/ui-react";
import StatusBar from "./components/Shared/StatusBar/StatusBar";
import LeftHandMenu from "./components/Shared/LeftHandMenu/LeftHandMenu";
import MainPanel from "./components/Shared/MainPanel/MainPanel";
import { 
	LightTheme, 
	DarkTheme 
} from "./utils/baseTheme";
import {
	ThemeProvider,
	StyledEngineProvider,
	Box
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DataProvider from "./dataprovider/dataProvider";
import authenticatedRequest from "./utils/authenticatedRequest";
import { BackToTop } from "./components/Shared/BackToTop/BackToTop";
import BezosSignIn from "./components/BezosSignIn/BezosSignIn";

export const DataContext = createContext();

function App() {

	const modeDefault = JSON.parse(localStorage.getItem("OP-Theme"));
	const [state, setState] = useState({
		open: false,
		darkMode: modeDefault || false
	});

	const setTheme = () => {

		localStorage.setItem("OP-Theme", JSON.stringify(!state.darkMode));

		setState(previousState => {
			return {
				...previousState,
				darkMode: !state.darkMode,
			};
		});
	};	

	const getDrawerStatus = (props) => {
		setState(previousState => {
			return {
				...previousState,
				open: props
			};
		});
	};

	const drawerWidth = 320;
	const DrawerHeader = styled("div")(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	}));

	const dataProvider = useRef(null);
	useMemo(() => {
		dataProvider.current = new DataProvider(authenticatedRequest);
	}, []);

	const formFields = {
		signIn: {
			username: {
				placeholder: "Enter username...",
				label: "Username",
				margin: "normal",
				size: "small"
			},
			password: {
				placeholder: "Enter password...",
				label: "Password",
				margin: "normal",
				size: "small"
			}
		}
	};

	const components = {
		SignIn: BezosSignIn
	};
	
	return (
		<Authenticator
			hideSignUp={true}
			components={components}
			formFields={formFields}
			variation="modal">
			{({signOut}) => (
				<DataContext.Provider value={{ dataProvider: dataProvider.current }}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={state.darkMode ? DarkTheme : LightTheme }>
							<Router>
								<Box 
									sx={{ 
										display: "flex",
										backgroundColor: theme => theme.palette.background.default,
										minHeight: "100vh",
										height: "100%",
										width: "100%",
									}}
								>
									<CssBaseline />
									<StatusBar
										onHandleDrawer={getDrawerStatus}
										isDrawerVisible={state.open}
										drawerWidth={drawerWidth}
										check={state.darkMode}
										onSignOut={signOut}
										change={() => setTheme()}
									/>
									<LeftHandMenu
										onHandleDrawer={getDrawerStatus}
										isDrawerVisible={state.open}
										drawerWidth={drawerWidth}
									/>
									<Box
										component="main"
										sx={{ flexGrow: 1, p: 3, marginBottom: "3rem" }}
									>
										<DrawerHeader />
										<MainPanel />
										<BackToTop />
									</Box>
								</Box>
							</Router>
						</ThemeProvider>
					</StyledEngineProvider>
				</DataContext.Provider>
			)}
		</Authenticator>
	);
}

export default App;

