import React, {
	useState,
	useEffect,
	useCallback
} from "react";
import {
	TextField,
	InputAdornment,
	IconButton
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import debounce from "lodash/debounce";

const SearchFilter = ({ 
	filter, 
	onFilterChange,
	label
}) => {
	const [localFilter, setLocalFilter] = useState(filter);
	
	useEffect(() => {
		setLocalFilter(filter);
	}, [filter]);

	const handleClear = () => {
		setLocalFilter("");
		onFilterChange("");
	};

	const debounceOnChange = useCallback(debounce(value => {
		onFilterChange(value.trim());
	}, 1000), [onFilterChange]);

	useEffect(() => {
		return () => {
			debounceOnChange.cancel();
		};
	}, [debounceOnChange]);

	return (
		<TextField
			label={label}
			size="small"
			value={localFilter}
			sx={{ mr: 2, mb: 2, maxWidth: "200px" }}
			onChange={e => {
				setLocalFilter(e.target.value);
				debounceOnChange(e.target.value);
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						{localFilter && (
							<IconButton size="small" onClick={handleClear}>
								<ClearIcon />
							</IconButton>
						)}
					</InputAdornment>
				),
			}}
		/>
	);
};

export default SearchFilter;
