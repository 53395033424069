import React from "react";
import {
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { StatusBox } from "../../Shared/StatusBox/StatusBox";
import { getEmailStatus } from "../../../utils/getEmailStatus";
import Modal from "../../Shared/Modal/ModalEmail";
import {
	getFormattedEndTime,
	getFormattedStartTime
} from "../../../utils/date";
import { RecordsTotal } from "../../Shared/ResultsFound/ResultsFound";

export const EmailTable = ({
	tableTitle,
	tableData,
	startDate,
	endDate,
	type,
}) => {

	const getParams = (item) => {
		const params = new URLSearchParams();
		params.append("start_time", getFormattedStartTime(startDate));
		params.append("end_time", getFormattedEndTime(endDate));
		params.append("seller_platform_id", item.seller_platform_id);
		return params.toString();
	};

	const ModalBody = ({ type, item }) => {	
		let messageTitle, messageBody, url;
	
		if (type === "fraudrisk") {
			messageTitle = "Confirm Fraud Risk email";
			messageBody = "in status 'fraud risk'?";
			url = "/v1/fraud-risk/email?";
		} else {
			messageTitle = "Confirm On Back Order email";
			messageBody = "on back order, for the specified date range?";
			url = "/v1/on-back-order/email?";
		}
	
		return (
			<Modal
				buttonName={"send email"}
				buttonType={"outlined"}
				messageTitle={messageTitle}
				messageBody={`Please confirm you wish to send ${item.seller_code} an email for the ${item.orders_not_emailed} un-notified order(s) ${messageBody}`}
				url={url}
				params={getParams(item)}
			/>
		);
	};
	

	return (
		<Paper sx={{ p: 2, width: "100%", marginTop: 5 }} elevation={2}>
			<Typography variant="h6" sx={{ mb: 2 }}>
				{tableTitle} <RecordsTotal total={tableData.length} />
			</Typography>
			<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell className="table-header">Seller</TableCell>
							<TableCell className="table-header">Number of orders</TableCell>
							<TableCell className="table-header">Seller contact email</TableCell>
							<TableCell className="table-header">Seller contact status</TableCell>
							<TableCell className="table-header"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tableData.length > 0 ?
							(
								tableData.map((item, index) =>
									<TableRow key={index}>
										<TableCell>{item.seller_code}</TableCell>
										<TableCell>{item.order_count}</TableCell>
										<TableCell>{item.seller_contact}</TableCell>
										<TableCell>
											<StatusBox
												status={getEmailStatus(item.orders_not_emailed, item.order_count)}
												statusType={"email"}
											/>
										</TableCell>
										<TableCell>
											{getEmailStatus(item.orders_not_emailed, item.order_count) !== "contacted" &&
												<ModalBody type={type} item={item} />
											}
										</TableCell>
									</TableRow>)
							)
							:
							(
								<TableRow>
									<TableCell colSpan="5" className='table-error-message-text'>No results
										found</TableCell>
								</TableRow>
							)
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};
