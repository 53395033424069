import { capitalizeFirstLetter } from "./text";

export const handleAxiosError = (error) => {
    
	if (error.response && error.response.data) {
		return capitalizeFirstLetter(error.response.data.message);
	} else {
		return "An unknown error occurred";
	}
  
};
  