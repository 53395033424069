export const getSellerDetails = (seller, type) => {

	if (!seller || !type) return;

	let sellerArray = seller.split("-");
	
	if (type === "id") return parseInt(sellerArray[0]);
	if (type === "code") return sellerArray[1];
	if (type === "name") return sellerArray[2];

	return;
};
