export const comparatorTypes = [
	{
		"id" : 1,
		"value": "==",
		"name": "Equal to (==)"
	},
	{
		"id" : 2,
		"value": "!=",
		"name": "Not equal to (!=)"
	},
	{
		"id" : 3,
		"value": ">",
		"name": "Greater than (>)"
	},
	{
		"id" : 4,
		"value": "<",
		"name": "Less than (<)"
	},
	{
		"id" : 5,
		"value": ">=",
		"name": "Greater than or equal to (>=)"
	},
	{
		"id" : 6,
		"value": "<=",
		"name": "Less than or equal to (<=)"
	},
];