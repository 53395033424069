import React, { useState } from "react";
import DropDown from "../DropDown/DropDown";
import { MenuItem } from "@mui/material";
import { 
	get24HourClock, 
	getQuarterHourIncrements
} from "../../../utils/time";

export default function TimePicker({ onTimeChange, time }){

	const timesplit = time.split(":");
	const [state, setState] = useState({
		hour: timesplit[0] || "00",
		minute: timesplit[1] || "00",
	});

	const {hours} = get24HourClock();
	const {minutes} = getQuarterHourIncrements();

	const optionsHour = hours.map(hour => (
		<MenuItem key={hour} value={hour}>
			{hour}
		</MenuItem>
	));

	const optionsMinute = minutes.map(minute => (
		<MenuItem key={minute} value={minute}>
			{minute}
		</MenuItem>
	));

	const onHourSelect = (hr) => {
		setState(previousState => {
			return {
				...previousState,
				hour: hr
			};
		});
		onTimeChange(`${hr}:${state.minute}`);
	};

	const onMinuteSelect = (min) => {
		setState(previousState => {
			return {
				...previousState,
				minute: min
			};
		});
		onTimeChange(`${state.hour}:${min}`);
	};

	return (
		<>
			<DropDown
				label={"Hour:"}
				id={"select_hour"}
				onChangeSelect={onHourSelect}
				selected={state.hour}
				sx={{ minWidth: 70, mr: 2, mb: 2, backgroundColor: theme => theme.palette.statusbox.main }}
				size="small"
				map={optionsHour}
			/>
			<DropDown
				label={"Minute:"}
				id={"select_minute"}
				onChangeSelect={onMinuteSelect}
				selected={state.minute}
				sx={{ minWidth: 70, backgroundColor: theme => theme.palette.statusbox.main }}
				size="small"
				map={optionsMinute}
			/>	
		</>
	);

}