import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import React from "react";

const earliestPossibleDate = new Date("2018-01-01T00:00:00Z");

let maxDateEnd = new Date();
maxDateEnd.setDate(maxDateEnd.getDate() + 1);

export const StartDatePicker = (props) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DesktopDatePicker
				label="Start Date:"
				inputFormat="dd/MM/yyyy"
				value={props.date}
				minDate={earliestPossibleDate}
				shouldDisableDate={(date) => date < earliestPossibleDate}
				disableFuture
				maxDate={new Date()}
				onChange={(newValue) => {
					props.onChange(newValue);
				}}
				renderInput={(params) => <TextField {...params} id="startdate" size="small" sx={{ maxWidth: 150, marginRight: "1rem", marginBottom: "1rem", backgroundColor: theme => theme.palette.statusbox.main, ...props.sx }} {...params} />}
			/>
		</LocalizationProvider>
	);
};

export const EndDatePicker = (props) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DesktopDatePicker
				label="End Date:"
				inputFormat="dd/MM/yyyy"
				value={props.date}
				minDate={earliestPossibleDate}
				shouldDisableDate={(date) => date < earliestPossibleDate}
				maxDate={maxDateEnd}
				onChange={(newValue) => {
					props.onChange(newValue);
				}}
				renderInput={(params) => <TextField {...params} id="enddate" size="small" sx={{ maxWidth: 150, mr: 0, mb: 2, backgroundColor: theme => theme.palette.statusbox.main, ...props.sx }} {...params} />}
			/>
		</LocalizationProvider>
	);
};
