export const capitalizeFirstLetter = (s) => {
	if (!s) return;
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeFirstLetterOfEachWord = (word) => {
	if (!word) return;
	return word.replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const removeUnderScore = (s) => {
	if (!s) return;
	return s.replace(/_/g, " ");
};