import React from "react";
import {
	StartDatePicker,
	EndDatePicker
} from "../DatePicker/DatePicker";
import { getDateFromDayNumber } from "../../../utils/date.js";

export default function DateRange(
	{
		onStartDateChange,
		onEndDateChange,
		startDate,
		endDate
	}) {

	if (typeof (startDate) === "number") {
		startDate = getDateFromDayNumber(startDate);
	}

	if (typeof (endDate) === "number") {
		endDate = getDateFromDayNumber(endDate);
	}

	return (
		<>
			<StartDatePicker onChange={onStartDateChange} date={startDate} />
			<EndDatePicker onChange={onEndDateChange} date={endDate} />
		</>
	);
}
