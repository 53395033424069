import React, {
	useRef,
	useState,
	useCallback,
	useEffect
} from "react";
import {
	Button,
	Box
} from "@mui/material";
import {
	Formik,
	Form
} from "formik";
import { TextfieldFormik } from "../../../Shared/TextField/TextField";
import * as Yup from "yup";
import useFetchData from "../../../Shared/FetchData/FetchData";
import { DataContext } from "../../../../App";
import DropDown from "../../../Shared/FormikDropDown";

export default function SellerDetails({
	onSellerDetailsFormSubmit,
	onPanelsVisibleChange,
	existingSeller,
	sellerOperation,
}) {
	const [state, setState] = useState({
		isDisabled: false,
	});

	const context = React.useContext(DataContext);
	const statuses = useFetchData(useCallback(() => context.dataProvider.getSellerStatuses(), [context.dataProvider]));

	const [selectedStatus, setSelectedStatus] = useState(existingSeller?.status || "");

	const page_data = {
		statuses: {
			resultsData: statuses.results,
			status: statuses.status,
			isLoading: statuses.isLoading,
		},
	};

	const INITIAL_FORM_STATE = {
		mintsoft_id: existingSeller?.mintsoft_id || 0,
		short_name: existingSeller?.short_name || "",
		name: existingSeller?.name || "",
		contact: existingSeller?.contact || "",
		reports_sent_to: existingSeller?.reports_sent_to || "",
		sub_warehouse_id: existingSeller?.sub_warehouse_id || 0,
		default_packaging_type_id: existingSeller?.default_packaging_type_id || 0,
		status: existingSeller?.status || "",
	};

	const FORM_VALIDATION = Yup.object().shape({
		mintsoft_id: Yup.number()
			.required("Mintsoft ID of the Seller is required"),
		short_name: Yup.string()
			.max(3, "Short name must not exceed 3 characters")
			.required("Seller short name required"),
		name: Yup.string()
			.required("Seller name required"),
		contact: Yup.string()
			.required("Contact email address is required")
			.email("Contact email address is required"),
		reports_sent_to: Yup.string(),
		sub_warehouse_id: Yup.number()
			.default(0)
			.min(0),
		default_packaging_type_id: Yup.number()
			.default(0)
			.min(0),
		status: Yup.string()
			.required("Please select a Status from the list"),
	});

	const onSubmitForm = (values) => {
		onSellerDetailsFormSubmit(values);
		onPanelsVisibleChange({
			isSellerDetailsPanelVisible: false,
			isFinanceDetailsPanelVisible: false,
			isWarehouseMappingsPanelVisible: true,
		});
	};

	useEffect(() => {
		if (sellerOperation === "edit") {
			setState(previousState => {
				return {
					...previousState,
					isDisabled: true
				};
			});
		}
	}, [sellerOperation]);

	const handleStatusChange = (e) => {
		formikRef.current.setFieldValue("status", e);
		setSelectedStatus(e);
	};

	const formikRef = useRef(null);

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				innerRef={formikRef}
				onSubmit={(values) => onSubmitForm(values)}
			>
				{() => (
					<Form
						noValidate
						autoComplete="off"
					>
						<TextfieldFormik
							name="mintsoft_id"
							label="Mintsoft ID"
							disabled={state.isDisabled}
							type="number"
							min="0"
							required
						/>

						<TextfieldFormik
							name="short_name"
							label="Short name"
							disabled={state.isDisabled}
							required
						/>

						<TextfieldFormik
							name="name"
							label="Name"
							disabled={state.isDisabled}
							required
						/>

						<TextfieldFormik
							name="contact"
							label="Contact email"
							type="email"
							required
						/>

						<TextfieldFormik
							name="reports_sent_to"
							label="Reports sent to email"
							type="email"
						/>

						<TextfieldFormik
							name="sub_warehouse_id"
							label="Sub Warehouse ID"
							type="number"
						/>

						<TextfieldFormik
							name="default_packaging_type_id"
							label="Default packaging type ID"
							type="number"
						/>

						<DropDown
							id="status"
							name="status"
							label="Status"
							value={selectedStatus}
							onChange={handleStatusChange}
							data={page_data.statuses.resultsData.sort((b, a) => a.status_name > b.status_name ? -1 : 1).map((s) => ({
								key: s.id,
								value: s.status_name,
								name: s.status_name
							}))}
							required={true}
						/>

						<Box className="align-content-right">
							<Button
								type="submit"
								variant="contained"
							>
								Confirm
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</>
	);
}