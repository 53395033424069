import * as React from "react";
import { LoadableContent } from "../LoadableContent/LoadableContent";
import {
	Card,
	CardContent
} from "@mui/material";


import { BarChart } from "@mui/x-charts/BarChart";


export default function BarChartCard({
	loadingStatus,
	titleTextShort,
	chartData,
	labels
}) {
	return (
		<Card sx={{ width: "100%", height: "100%" }} className="align-items-vertical">
			<LoadableContent isLoading={loadingStatus} marginBottom={8}>
				<CardContent sx={{ textAlign: "center" }}>
					<BarChart
						height={150}
						series={[
							{ data: chartData, label: titleTextShort, id: "data", stack: "total" },
						]}
						xAxis={[{ data: labels, scaleType: "band" }]}
					/>
				</CardContent>
			</LoadableContent>
		</Card>
	);
}
