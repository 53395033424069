import React from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import rateLimit from "axios-rate-limit";
import ErrorPage from "../components/ErrorPage/ErrorPage";

const authenticatedRequest = rateLimit(axios.create({
	baseURL: process.env.REACT_APP_API_URL_CHECKERS
}), { maxRPS: 3 });
authenticatedRequest.getMaxRPS();

authenticatedRequest.interceptors.request.use(
	async (config) => {
		const data = await Auth.currentSession();
		config.headers.Authorization = `Bearer ${data.accessToken.jwtToken}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

authenticatedRequest.interceptors.request.use(
	(request) => {
		if (!request.headers) {
			request.headers = {};
		}
		request.headers["Accept"] = "application/json";

		const getURLValue = request.url.substring(0, 4);
		if (getURLValue !== "/v1/") {
			request.baseURL = process.env.REACT_APP_API_URL;
		}
    
		return request;
	},
	(error) => {
		return Promise.reject(error);
	},
);

authenticatedRequest.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {

		// 404 status is handled via MainPanel
		if (error.response && [401, 403, 500].includes(error.response.status) || 
		error.message === "Network Error") {
			<ErrorPage />;
		} else {
			return Promise.reject(error);
		}

	},
);

export default authenticatedRequest;
