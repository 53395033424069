import React from "react";
import Alert from "@mui/material/Alert";

export const AlertBox = ({ severity, message, ...props }) => {
   
	if (!severity || !message) return;

	return (
		<Alert 
			sx={{...props.sx, backgroundColor: "alert.main"}} 
			props={props} 
			severity={severity} 
			variant="outlined"
		>
			{message}
		</Alert>
	);
};