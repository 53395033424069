import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AntSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		color: "rgb(161, 51, 51)",
		"&.Mui-checked": {
			color: "green",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: "rgb(60,179,113)"
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		color: "blue",
		opacity: 1,
		backgroundColor: "red"
	},
}));