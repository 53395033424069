import React from "react";
import {
	Select,
	MenuItem,
	InputLabel,
	FormControl
} from "@mui/material";

export default function PageRowsDropDown ({
	resultsTotalCount,
	rowsPerPage,
	pageRowOptions,
	onChangePageRow
}) {
	let updatedOptions = [];

	if (resultsTotalCount < pageRowOptions[pageRowOptions.length - 1]) {
		updatedOptions = pageRowOptions.filter((option) => option <= resultsTotalCount);
		updatedOptions.push("All");
	}
	else {
		updatedOptions = pageRowOptions;
	}

	return (
		resultsTotalCount > pageRowOptions[0] &&
			<FormControl sx={{ mt: 2 }}>
				<InputLabel id="pageRows-select">Rows</InputLabel>
				<Select
					labelId="pageRows-select"
					id="pageRows"
					label="Rows"
					size="small"
					fullWidth
					value={rowsPerPage >= resultsTotalCount ? resultsTotalCount : rowsPerPage}
					onChange={(e) => onChangePageRow(e.target.value)}
				>
					{updatedOptions.map((item, i) => (
						<MenuItem
							key={i}
							value={item === "All" ? resultsTotalCount : item}
						>
							{item === "All" ? "All" : item}
						</MenuItem>
					))}
				</Select>
			</FormControl>
	);
}
