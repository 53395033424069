import React from "react";
import "./StatusBar.scss";
import {
	Toolbar,
	Box,
} from "@mui/material";
import { Logo } from "./logo";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";

export default function StatusBarBasic() {

	const AppBar = styled(MuiAppBar, {    
		shouldForwardProp: (prop) => prop !== "open",
	})(() => ({}));

	return (
		<Box className="AppBar">

			<AppBar
				position="fixed"
				open={false}
				color="appbar"
				enableColorOnDark
			>
				<Toolbar>

					<Box sx={{ width: "100%" }}>
						<Logo />
					</Box>

				</Toolbar>

			</AppBar>
		</Box>
	);

}
