import React from "react";
import ErrorPage from "../components/ErrorPage/ErrorPage";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return (
				<ErrorPage errorType={"boundary"} />
			);
		}
		return this.props.children;
	}

}

export default ErrorBoundary;
