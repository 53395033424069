export const getEmojiAnswer = (answer) => {

	if (typeof answer !== "boolean" && typeof answer !== "string") {
		return answer;
	}
  
	let normalizedAnswer;
  
	if (typeof answer === "boolean") {
		normalizedAnswer = answer ? "yes" : "no";
	} else {
		// Handle string inputs
		const lowerCaseAnswer = answer.toLowerCase();
		if (lowerCaseAnswer === "true") {
			normalizedAnswer = "yes";
		} else if (lowerCaseAnswer === "false") {
			normalizedAnswer = "no";
		} else {
			normalizedAnswer = lowerCaseAnswer;
		}
	}
  
	switch (normalizedAnswer) {
	case "yes":
		return "✅";
	case "no":
		return "❌";
	default:
		return "Unknown";
	}
    
};
  