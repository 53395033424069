import { formatISO } from "date-fns";

const padNumber = (n) => {
	const padN = "00" + n;
	return padN.substring(padN.length - 2);
};

export const getFormattedStartTime = (t) => {
	const startTime = new Date(t);
	return formatISO(startTime).substring(0, 19);
};

export const getFormattedEndTime = (t) => {
	const endTime = new Date(t);
	endTime.setHours(endTime.getHours() + 24);
	return formatISO(endTime).substring(0, 19);
};

export const getDisplayDate = (t, errorMsg, s) => {
	const isZeroTime = t === "0001-01-01T00:00:00Z";
	const separator = s ? s : "-";

	if (t && !isZeroTime) {
		const date = new Date(t);
		return date.getFullYear() + separator +
            padNumber(date.getMonth() + 1) + separator +
            padNumber(date.getDate());
	}

	if (!errorMsg) {
		return "Unavailable";
	}

	return errorMsg;
};

export const getDisplayDateTime = (t, errorMsg, s) => {
	const displayDate = getDisplayDate(t, errorMsg, s);
  
	if (typeof displayDate === "string" && displayDate !== "Unavailable") {
		const date = new Date(t);
		const time = `${padNumber(date.getHours())}:${padNumber(date.getMinutes())}:${padNumber(date.getSeconds())}`;
		return `${displayDate} ${time}`;
	}
  
	return displayDate;
};

export const getDisplayDateAlt = (t, errorMsg, s) => {
	let isZeroTime = t === "0001-01-01T00:00:00Z";
	const separator = s ? s : "-";

	if (isZeroTime) {
		return "";
	}

	if (t && !isZeroTime) {
		const date = new Date(t);
		return padNumber(date.getDate()) + separator +
			padNumber(date.getMonth() + 1) + separator +
			date.getFullYear();
	}

	if (!errorMsg) {
		return "";
	}

	return errorMsg;
};

export const getCurrentDateTruncated = () => {
	let date = new Date();
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);
	return date;
};

export const getTruncatedDate = () => {
	let date = new Date();
	date.setHours(14);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);
	return date;
};

export const getDateFromDayNumber = (dayNumber) => {
	if (dayNumber === undefined) {
		return "";
	}

	const initDate = getCurrentDateTruncated();
	return initDate.setDate(initDate.getDate() + dayNumber);
};

export const getUpdatedDate = (d, t, p) => {
	const time = t.split(":");
	const isoDate = formatISO(d);
	if (p === "despatch") return `${isoDate.substring(0, 11)}${time[0]}:${time[1]}:00`;
	if (p === "stock") return `${isoDate.substring(0, 11)}${time[0]}:${time[1]}:00.000Z`;
};
