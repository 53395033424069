export const get24HourClock = () => {
	const hoursArray = Array.from({length: 24}, (_, i) => i);
	const hourLabelsArray = hoursArray.map(hour => hour.toString().padStart(2, "0"));
	return {hours: hourLabelsArray};
};

export const getQuarterHourIncrements = () => {
	const minutesArray = Array.from({length: 4}, (_, i) => i * 15);
	const minuteLabelsArray = minutesArray.map(minute => `${minute.toString().padStart(2, "0")}`);
	return {minutes: minuteLabelsArray};
};

export const formatSeconds = (input) => {
	const opts = { minimumFractionDigits: 1, maximumFractionDigits: 1 };

	if (input < 60) {
		return input + " seconds";
	}

	if (input < 3600) {
		return (input/60).toLocaleString(undefined, opts) + " minutes";
	}

	return (input/3600).toLocaleString(undefined, opts) + " hours";
};

const padNumber = (n) => {
	const padN = "00" + n;
	return padN.substring(padN.length - 2);
};

export const getFormattedTime = (t) => padNumber(t.getHours()) + ":" + padNumber(t.getMinutes());

export const getFormattedDateTime = (t) => t.toISOString().substring(0, 19).replace("T", " ");

export const getFormattedCutoffTime = (cutoffTimeHour, cutoffTimeMinute) => {
	if (cutoffTimeHour == null || cutoffTimeMinute == null) {
		return null;
	}

	const minute = ("00" + cutoffTimeMinute).slice(-2);
	return cutoffTimeHour + "." + minute;
};
