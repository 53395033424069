import React from "react";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { LightTheme } from "../../../utils/baseTheme";
import {
	ThemeProvider,
	StyledEngineProvider
} from "@mui/material";

export const DownloadButton = ({
	onClick,
	disabled,
	icon,
	text,
	sx
}) => {

	return (
		<>
			<Button
				title="Download"
				variant="contained"
				onClick={() => onClick()}
				disabled={disabled}
				sx={sx}
			>
				{text}{icon && <FileDownloadIcon />}
			</Button>
		</>
	);

};

export const DeleteButton = ({
	onClick,
	icon,
	text,
}) => {

	return (
		<>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={LightTheme}>
					<Button
						title="Delete"
						variant="contained"
						color="destructive"
						onClick={() => onClick()}
					>
						{text}{icon && <DeleteIcon />}
					</Button>
				</ThemeProvider>
			</StyledEngineProvider>
		</>
	);

};
