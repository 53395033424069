import React, {
	useState,
	useEffect
} from "react";
import "./DebugView.scss";
import { Auth } from "aws-amplify";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import {
	Box,
	Grid,
	Paper,
	Tooltip
} from "@mui/material";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function Debug() {

	const [token, setToken] = useState("");
	const [refreshToken, setRefreshToken] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		Auth.currentSession()
			.then(data => {
				setToken(data.accessToken.jwtToken);
				setIsLoading(false);
			});
	}, [refreshToken]);

	const onClickCopyToken = async () => {
		setIsLoading(true);
		setRefreshToken(prev => !prev);
		await navigator.clipboard.writeText(token);
	};

	return (
		<LoadableContent isLoading={isLoading} marginBottom={5}>
			<Box id="debug">
				<Grid container>
					<PageHeading pageTitle={"Access Token"} sx={{ mb: 1 }}/>
					<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
						<Grid item xs={12}>
							<Tooltip
								title="Click to copy"
								placement="top"
							>
								<Box
									className="debug-token"
									onClick={() => onClickCopyToken()}
								>
									{token}
								</Box>
							</Tooltip>
						</Grid>
					</Paper>
				</Grid>
			</Box>
		</LoadableContent>
	);

}
