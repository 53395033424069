import * as React from "react";
import { LoadableContent } from "../LoadableContent/LoadableContent";
import {
	Card,
	CardContent,
	Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { formatNumber } from "../../../utils/integer";

export default function MediaCard({
	loadingStatus,
	titleTextShort,
	titleTextLong,
	bodyText,
	imageName,
	imageHeight,
	headingVariant,
	bodyVariant
}) {

	const LightTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "rgba(60,184,121,1)",
			color: "rgba(255,255,255,1)",
			boxShadow: theme.shadows[1],
			fontSize: 16,
		},
	}));

	return (
		<Card sx={{ width: "100%", height: "100%" }} className="align-items-vertical">
			<LoadableContent isLoading={loadingStatus} marginBottom={8} sx={{ mt: 4 }}>
				<CardContent sx={{ textAlign: "center" }}>
					<img
						src={`/cards/${imageName}.png`}
						className='card-image'
						height={imageHeight || 180}
						alt={titleTextLong}
						title={titleTextLong}
					/>
					<Typography
						gutterBottom
						variant={headingVariant || "h6"}
						component="div"
						sx={{ fontWeight: 500, textAlign: "center" }}
						className="card-title"
					>
						{titleTextShort}
					</Typography>
					<LightTooltip
						title={titleTextLong}
						arrow
					>
						<Typography
							gutterBottom
							variant={bodyVariant || "h3"}
							component="div"
							sx={{ fontWeight: 900, margin: 0 }}
						>
							{formatNumber(bodyText)}
						</Typography>
					</LightTooltip>
				</CardContent>
			</LoadableContent>
		</Card >
	);
}
