import React, { useCallback } from "react";
import * as Yup from "yup";
import {
	Form,
	Formik
} from "formik";
import { TextfieldFormik } from "../../Shared/TextField/TextField";
import DropDown from "../../Shared/FormikDropDown/DropDown";
import {
	Box,
	Button
} from "@mui/material";
import { DataContext } from "../../../App";
import UseFetchData from "../../Shared/FetchData/FetchData";
import { getDisplayDateTime } from "../../../utils/date";

export function SummaryDetails({
	onSummaryDetailsFormSubmit,
	onPanelsVisibleChange,
	existingWarehouse
}) {

	const context = React.useContext(DataContext);
	const platformIDs = UseFetchData(useCallback(() => context.dataProvider.getPlatformIDs(), [context.dataProvider]));

	const INITIAL_FORM_STATE = {
		id: existingWarehouse.id ?? 0,
		created_at: getDisplayDateTime(existingWarehouse.created_at) ?? "",
		name: existingWarehouse.name ?? "",
		short_code: existingWarehouse.short_code ?? "",
		platform_id: existingWarehouse.platform_id ?? "",
		backward_sync_client_id: existingWarehouse.backward_sync_client_id ?? 0,
		timezone: existingWarehouse.timezone ?? "Europe/London",
		currency: existingWarehouse.currency ?? "GBP",
		form_url: existingWarehouse.form_url ?? "",
		minimum_api_call_time_minutes: existingWarehouse.minimum_api_call_time_minutes ?? 0,
		cutoff_time_hour: existingWarehouse.cutoff_time_hour ?? 0,
		cutoff_time_minute: existingWarehouse.cutoff_time_minute ?? 0,
		bezos_system_id: existingWarehouse.bezos_system_id ?? "",
		location_code: existingWarehouse.location_code ?? "",
		country: existingWarehouse.country ?? "",
		default_supplier: existingWarehouse.default_supplier ?? "",
		minutes_after_cutoff_for_despatch_email: existingWarehouse.minutes_after_cutoff_for_despatch_email ?? 0,
		default_client_id: existingWarehouse.default_client_id ?? 0,
	};

	const FORM_VALIDATION = Yup.object().shape({
		name: Yup.string()
			.required("Enter the Warehouse name e.g. UK-BZT"),
		short_code: Yup.string()
			.required("Enter the Short Code e.g. BZS (max 4 characters)."),			
		platform_id: Yup.number()
			.required("Select a Platform ID from the drop down."),
		backward_sync_client_id: Yup.string(),
		timezone: Yup.string()
			.required("Enter the Timezone e.g. Europe/London"),
		currency: Yup.string()
			.required("Enter the Currency e.g. EUR"),
		form_url: Yup.string(),
		minimum_api_call_time_minutes: Yup.number(),
		cutoff_time_hour: Yup.number(),
		cutoff_time_minute: Yup.number(),
		bezos_system_id: Yup.number()
			.required("Enter the Bezos System ID e.g. 4 (number only)."),
		location_code: Yup.string()
			.required("Enter the Location Code e.g. BZTSW1"),
		country: Yup.string()
			.required("Enter the Country e.g. UNITED KINGDOM"),
		default_supplier: Yup.string(),
		minutes_after_cutoff_for_despatch_email: Yup.number(),
		default_client_id: Yup.number(),
	});

	const onSubmitForm = (values) => {
		values.country = values.country.toUpperCase();
		values.currency = values.currency.toUpperCase();
		onSummaryDetailsFormSubmit(values);
		onPanelsVisibleChange({
			isSummaryDetailsPanelVisible: false,
			isAdditionalOptionsPanelVisible: true,
			isContactDetailsPanelVisible: false,
		});

	};

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitForm(values)}
			>
				{({ values }) => (
					
					<Form
						noValidate
						autoComplete="off"
					>

						{Object.keys(existingWarehouse).length > 0 &&
						<>
							<TextfieldFormik
								name="id"
								label="ID"
								disabled={true}
							/>

							<TextfieldFormik
								name="created_at"
								label="Created At"
								disabled={true}
							/>
						</>
						}

						<TextfieldFormik
							name="name"
							label="Name"
							required
						/>

						<DropDown
							id="platform_id"
							name="platform_id"
							label="Platform ID"
							value={values.platform_id}
							data={platformIDs.results.map((p) => ({
								key: p.id,
								value: p.id,
								name: p.name
							}))}
							required={true}
							sx={{ width: "100%", mt: 2, mb: 1 }}
							size="small"
						/>

						<TextfieldFormik
							name="backward_sync_client_id"
							label="Backward Sync Client ID"
							type="number"
						/>

						<TextfieldFormik
							name="timezone"
							label="Timezone"
							required
						/>

						<TextfieldFormik
							name="currency"
							label="Currency"
							required
						/>

						<TextfieldFormik
							name="form_url"
							label="Form URL"
						/>

						<TextfieldFormik
							name="minimum_api_call_time_minutes"
							label="Minimum API Call Time (Min)"
							type="number"
						/>

						<TextfieldFormik
							name="cutoff_time_hour"
							label="Cutoff Time (Hr)"
							type="number"
						/>
						<TextfieldFormik
							name="cutoff_time_minute"
							label="Cutoff Time (Min)"
							type="number"
						/>

						<TextfieldFormik
							name="short_code"
							label="Short Code"
							inputProps={{
								maxLength: 4
							}}
							required
						/>

						<TextfieldFormik
							name="bezos_system_id"
							label="Bezos System ID"
							type="number"
							required
						/>

						<TextfieldFormik
							name="location_code"
							label="Location Code"
							required
						/>

						<TextfieldFormik
							name="country"
							label="Country"
							required
						/>

						<TextfieldFormik
							name="default_supplier"
							label="Default Supplier"
						/>

						<TextfieldFormik
							name="minutes_after_cutoff_for_despatch_email"
							label="After Cutoff Time Despatch Email"
							type="number"
						/>

						<TextfieldFormik
							name="default_client_id"
							label="Default Client ID (when using sub clients)"
							type="number"
						/>

						<Box className="align-content-right">
							<Button
								type="submit"
								variant="contained"
							>
								Confirm
							</Button>
						</Box>

					</Form>
				)}
			</Formik>
		</>
	);
}