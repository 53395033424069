import React, {
	useContext,
	useState,
	useEffect,
} from "react";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Button,
} from "@mui/material";
import { DataContext } from "../../App";
import DataTable from "../Shared/Table/Table";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import MoreHorizIcon from "@mui/icons-material/ManageSearch";
import { useNavigate } from "react-router-dom";
import { filterByKeyValue } from "../../utils/object";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "react-router-dom";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function WarehouseConfiguration() {

	const context = useContext(DataContext);
	const navigate = useNavigate();
	const [state, setState] = useState({
		isLoading: null,
		warehouses: [],
		warehousesLive: [],
		warehousesOffline: [],
		resultsTotal: 0,
	});

	const params = new URLSearchParams({
		include_non_live: true,
	});

	useEffect(() => {
		const getWarehouses = async () => {
			setState({
				isLoading: true,
			});

			await context.dataProvider.getWarehouseConfigurations(params)
				.then((res) => {
					setState({
						warehouses: res.data,
						warehousesLive: filterByKeyValue(res.data, "is_live", true),
						warehousesOffline: filterByKeyValue(res.data, "is_live", false),
						resultsTotal: res.data.length,
						isLoading: false,
					});
				});
		};
		getWarehouses();
	}, [context.dataProvider]);

	const tableHeaders = [	
		{
			key: "name",
			label: "Name",
		},
		{
			key: "short_code",
			label: "Short Code",
		},
		{
			key: "location_code",
			label: "Location Code",
		},
		{
			key: "cutoff_time_hour",
			label: "Cutoff Time (Hr)",
		},
		{
			key: "cutoff_time_minute",
			label: "Cutoff Time (Min)",
		},
		{
			key: "contact_name",
			label: "Contact Name",
		},
		{
			key: "contact_email",
			label: "Contact Email",
		},
		{
			key: "ops_support_contact",
			label: "Ops Support Contact",
		},
		{
			key: "custom_component",
			label: "",
			component: (rowData) => <MoreDetails data={rowData} />,
		},
	]; 

	const MoreDetails = ({ data }) => {

		if (!data.data.id) return;

		const getWarehouseDetails = () => {
			const warehouseDetails = filterByKeyValue(state.warehouses, "id", data.data.id);
			navigate("/warehouse-detail", { 
				state: { warehouse: warehouseDetails } 
			});
		};

		return (
			<Button 
				title="Click for more details"
				variant="outlined"
				onClick={() => getWarehouseDetails()}
			>
				<MoreHorizIcon fontSize="small" />
			</Button>
		);
	};

	const HeadingContent = () => (
		<>
			<Grid item xs />
			<Grid item xs="auto">
				<Button variant="contained" component={RouterLink} to={"/warehouse-create"} state={{ warehouseOperation: "create" }} >
					Create a Warehouse<AddIcon />
				</Button>
			</Grid>
		</>
	);

	const WarehousesLive = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Grid item xs={12}>
				<Typography variant="h5" mb={1}>
					Warehouses <StatusBox 
						status={"true"} 
						statusType={"warehouse"}
						sx={{ ml: 1, mr: 1, position: "relative", bottom: "5px" }}
					/>
					<RecordsTotal total={state.warehousesLive.length} />
				</Typography>
				<Grid item xs />
			</Grid>
			<DataTable
				data={state.warehousesLive.sort((a, b) => a.id > b.id ? -1 : 1)}
				headers={tableHeaders}
				dateHeaders={[]}
				isLoading={state.isLoading}
				usePagination={false}
			/>
		</Paper>
	);

	const WarehousesOffline = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Grid item xs={12}>
				<Typography variant="h5" mb={1}>
					Warehouses <StatusBox 
						status={"false"} 
						statusType={"warehouse"}
						sx={{ ml: 1, mr: 1, position: "relative", bottom: "5px" }}
					/>
					<RecordsTotal total={state.warehousesOffline.length} />
				</Typography>
				<Grid item xs />
			</Grid>
			<DataTable
				data={state.warehousesOffline.sort((a, b) => a.id > b.id ? -1 : 1)}
				headers={tableHeaders}
				dateHeaders={[]}
				isLoading={state.isLoading}
				usePagination={false}
			/>
		</Paper>
	);

	return (
		<Box id="warehouse-configuration">
			<LoadableContent marginBottom={4} isLoading={state.isLoading}>
				<Grid container>
					<Grid item xs={12}>
						<PageHeading pageTitle={"Warehouse Configuration"} sx={{ mb:2 }}>
							<HeadingContent/>
						</PageHeading>
					</Grid>
					<Grid item xs={12} mb={2}>
						<WarehousesLive />
					</Grid>
					<Grid item xs={12} mb={2}>
						<WarehousesOffline />
					</Grid>
				</Grid>
			</LoadableContent>
		</Box>
	);

}
