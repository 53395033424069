import React from "react";
import { Grid } from "@mui/material";
import { CSVLink } from "react-csv";
import { DownloadButton } from "../Buttons/Buttons";

export const DownloadCSV = ({ data, isLoaded, csvFilename, sx }) => {

	if (!csvFilename || data.length === 0) return;

	const onClickCombineData = () => {
		if (!data) return [];
		return data.flat();
	};

	const Download = () => {
		return (
			<Grid item mb={2} className="download-csv-button">
				<CSVLink
					data={onClickCombineData()}
					filename={csvFilename}
					onClick={() => {}}
					id={csvFilename}
				>
					<DownloadButton
						onClick={() => {}}
						icon={true}
						disabled={!data && !isLoaded}
						text={"Download CSV"}
						sx={{ ...sx }}
					/>
				</CSVLink>
			</Grid>
		);
	};

	return (
		<Download />
	);

};
