import React from "react";
import { LoadableContent } from "../LoadableContent/LoadableContent";
import { removeUnderScore } from "../../../utils/text";
import { getDisplayDateTime } from "../../../utils/date.js";
import {
	Table,
	TableContainer,
	TableBody,
	TableCell,
	Pagination,
	TableHead,
	TableRow,
	Grid,
	Box
} from "@mui/material";
import "./Table.scss";
import { AlertBox } from "../Alert/Alert";
import ItemDisplay from "../ViewItems/ItemDisplay";

export default function DataTable({
	data,
	headers,
	dateHeaders,
	isLoading,
	usePagination,
	rowsPerPage,
	paginationPages,
	paginationCurrentPage,
	onChangePaginationCurrentPage,
	itemType,
	orderRulesRefData,
}) {

	const getPageValue = (e) => {
		let paginationNextPage;

		if (e.currentTarget.textContent.length > 0) {
			paginationNextPage = Number(e.currentTarget.innerText);
		} else if (e.currentTarget.querySelector("svg").getAttribute("data-testid") === "NavigateNextIcon") {
			paginationNextPage = Number(paginationCurrentPage) + 1;
		} else if (e.currentTarget.querySelector("svg").getAttribute("data-testid") === "NavigateBeforeIcon") {
			paginationNextPage = Number(paginationCurrentPage) - 1;
		} else {
			paginationNextPage = Number(paginationCurrentPage);
		}

		return paginationNextPage;
	};

	let paginationView;
	if (data.total <= rowsPerPage || usePagination === false) {
		paginationView = null;
	} else {
		paginationView = (
			<Pagination
				count={paginationPages}
				page={paginationCurrentPage}
				color="primary"
				onChange={(input) => {
					const page = getPageValue(input, paginationCurrentPage);
					onChangePaginationCurrentPage(page);
				}}
			/>
		);
	}

	const TableHeader = () => (
		<TableRow sx={{ verticalAlign: "bottom" }}>
			{headers.map((header, index) => (
				<TableCell key={index}>{removeUnderScore(header.label)}</TableCell>
			))}
		</TableRow>
	);
	
	const getTableRow = (obj, header) => {
		const value = obj[header.key];
	
		if (header.component) {
			const Component = header.component;
			return <Component data={obj} />;
		} else if (dateHeaders.includes(header.key)) {
			return getDisplayDateTime(value) || "-";
		} else {
			return String(value);
		}
	};
	
	const TableData = () => (
		data.map((item, rowIndex) => (
			<React.Fragment key={`row-${rowIndex}`}>
				<TableRow>
					{headers.map((header) => (
						<TableCell key={header.key}>
							{getTableRow(item, header)}
						</TableCell>
					))}
				</TableRow>
	
				{itemType && item.isVisible && (
					<TableRow>
						<TableCell colSpan={headers.length}>
							<ItemDisplay
								itemType={itemType}
								item={item}
								isVisible={item.isVisible}
								orderRulesRefData={orderRulesRefData}
							/>
						</TableCell>
					</TableRow>
				)}
			</React.Fragment>
		))
	);
	
	const TableMain = () => (
		<LoadableContent isLoading={isLoading}>
			<TableContainer sx={{ maxHeight: 600, overflow: "auto", mt: 0 }}>
				<Table stickyHeader>
					<TableHead sx={{ textTransform: "capitalize" }}>
						<TableHeader />
					</TableHead>
					<TableBody>
						{!isLoading && data.length > 0 ? (
							<TableData/>
						) : (
							<TableRow>
								<TableCell colSpan={headers.length} align="center">
									<AlertBox
										severity={"error"}
										message={"No results found"}
									/>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Grid
				container
				spacing={0}
				padding={2}
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid item xs={3}>
					{paginationView}
				</Grid>
			</Grid>
		</LoadableContent>
	);

	return (
		<Box className="data-table">
			<TableMain />
		</Box>
	);

}