import React, {
	useState,
	useContext
} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
	Snackbar,
	Box
} from "@mui/material";
import { DataContext } from "../../../App";
import { AlertBox } from "../Alert/Alert";

export default function ResponsiveDialog({
	buttonName,
	buttonType,
	messageTitle,
	messageBody,
	url,
	params,
	disabled,
}) {

	const context = useContext(DataContext);
	const [state, setState] = useState({
		isModalOpen: false,
		results: "",
		isLoading: false,
		showAlert: false,
	});

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

	const handleClickOpen = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});
	};

	const onAlertHandleClose = (reason) => {
		if (reason === "clickaway") {
			return;
		}

		setState(previousState => {
			return {
				...previousState,
				showAlert: false,
			};
		});
	};

	const handleClose = () => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
			};
		});
	};


	const onClickSendEmail = async () => {

		setState(previousState => {
			return {
				...previousState,
				isLoading: true
			};
		});

		const file = "";
		const postBodyData = new FormData();
		postBodyData.append("File", file);

		context.dataProvider.sendEmailCheckers(url, params, postBodyData)
			.then(response => {
				setState(previousState => {
					return {
						...previousState,
						results: response.data,
						isModalOpen: false,
						alertSeverity: "success",
						alertMessage: "Email sent successfully",
					};
				});
			})
			.catch(error => {
				setState(previousState => {
					return {
						...previousState,
						results: error.response.data,
						isModalOpen: false,
						alertSeverity: "error",
						alertMessage: "Error encountered whilst attempting to send email",
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				isLoading: false,
				showAlert: true,
			};
		});
	};

	return (
		<>
			<Button
				onClick={handleClickOpen}
				variant={buttonType}
				className="email-button"
				disabled={disabled}
			>
				{buttonName}
			</Button>

			<Dialog
				fullScreen={fullScreen}
				open={state.isModalOpen}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					{messageTitle}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{messageBody}
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{ paddingBottom: 2, paddingRight: 2 }}
				>
					<Button
						autoFocus
						onClick={handleClose}
						variant="outlined"
						sx={{ marginRight: 2 }}
					>
            Cancel
					</Button>
					<Button
						autoFocus
						onClick={onClickSendEmail}
						variant="contained"
					>
            Send Email
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar open={state.showAlert} autoHideDuration={8000} onClose={onAlertHandleClose} mb={2}>
				<Box>
					<AlertBox
						onClose={onAlertHandleClose}
						severity={state.alertSeverity}
						message={state.alertMessage}
					/>
				</Box>
			</Snackbar>
		</>
	);
}


